import React from "react";

import { Link } from "react-router-dom";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <>
      <div class="py-lg-4 py-0 pb-15">
        <div class="container">
          <div class="row text-lg-start text-center">

          <div class="col-lg-4 col-md-12 col-12 mb-3">
              <span>© {year} Smart Edu, Inc. All Rights Reserved</span>
            </div>

            <div class="col-lg-8 col-12 col-md-12 mb-3">
              <nav class="nav nav-footer justify-content-center">
                <Link class="nav-link" to="/about-us">
                  About{" "}
                </Link>
                <Link class="nav-link" to="/terms">
                  Terms & Conditions
                </Link>
                <Link class="nav-link" to="/privacy-policy">
                  Privacy Policy
                </Link>
                <Link class="nav-link" to="/contact-us">
                  Get Support
                </Link>
                <a
                  href="https://instagram.com/smarteduinc"
                  target="_blank"
                  class="nav-link text-muted"
                >
                  <i class="mdi mdi-instagram fs-3"></i>
                </a>

                <a
                  href="https://twitter.com/SmartEduInc1"
                  target="_blank"
                  class="nav-link text-muted"
                >
                  <i class="mdi mdi-twitter  fs-3"></i>
                </a>
              </nav>
            </div>
            
          </div>
        </div>
      </div>

      <nav class="navbar navbar-expand-lg navbar-default shadow-none fixed-bottom py-3 d-block d-lg-none bg-back">
        <div className="px-0">
          <div class="d-grid d-lg-none">
            <a href="https://wa.me/message/UMER6OLVEWYJP1"
              class="btn fs-5 fw-bold shadow-lg"
              style={{ borderRadius: "30px", background: "transparent",
                borderColor: '#2c4f40', color: '#2c4f40' }}
            >
              <i class="bi bi-cart"></i> Get Gold Plus Plan Now
              {" "}
              <del className="text-danger">₦1500</del>
              {" "}
              <span className="text-primary fw-bolder">₦1300</span>
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Footer;
