import React, { useState, useEffect } from "react";

import { useNavigate, Link } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import Footer from "./Footer";

const Terms = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [school, setSchool] = useState("None");

  const Signup = (e) => {
    e.preventDefault();
    navigate("../signup/" + school + "/Gold Plus");
  };

  const [progress, setProgress] = useState(20);

  return (
    <div onLoad={() => setProgress(100)}>
      <LoadingBar
        color="#B53471"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <nav class="navbar navbar-expand-lg navbar-default shadow-none fixed-top py-2">
        <div className="container-fluid px-0">
          <Link to="/" class="navbar-brand">
            <img src="../assets/smlogo.png" width="110" alt="" />
          </Link>

          <div class="ms-16 d-block d-xl-none d-lg-none">
            <Link to="/cart" class="text-purple2 fs-10 fw-medium">
              <i class="bi bi-cart"></i>
            </Link>
          </div>

          <button
            class="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-default"
            aria-controls="navbar-default"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="icon-bar top-bar mt-0"></span>
            <span class="icon-bar middle-bar"></span>
            <span class="icon-bar bottom-bar"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbar-default">
            <ul class="navbar-nav">
              <li class="nav-item px-3">
                <Link class="nav-link" to="/products">
                  Products
                </Link>
              </li>
              <li class="nav-item px-3">
                <Link class="nav-link fw-bold" to="/packages">
                  Packages
                </Link>
              </li>
              <li class="nav-item dropdown px-3">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarLanding"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Company
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-md shadow-none"
                  aria-labelledby="navbarLanding"
                >
                  <li>
                    <h4 class="dropdown-header">Company</h4>
                  </li>
                  <li>
                    <Link to="/about-us" class="dropdown-item">
                      About us
                    </Link>
                  </li>

                  <li>
                    <Link to="/contact-us" class="dropdown-item">
                      Contact us
                    </Link>
                  </li>

                  <li>
                    <Link to="/forum" class="dropdown-item">
                      Community Forum
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>

            <div class="ms-auto mt-3 mt-lg-0">
              <a
                class="btn btn-purple text-white shadow-lg"
                style={{ borderRadius: "30px" }}
                onClick={Signup}
              >
                Try us out for Free
              </a>
            </div>

            <div class="ms-lg-4 mt-3 ms-0 mt-lg-0">
              <Link
                to="/cart"
                class="btn fs-4 fw-medium shadow-lg"
                style={{
                  borderRadius: "30px",
                  background: "transparent",
                  borderColor: "#2c4f40",
                  color: "#2c4f40",
                }}
              >
                <i class="bi bi-cart"></i> Cart
              </Link>
            </div>
          </div>
        </div>
      </nav>

      <div class="pt-lg-20 pt-18 pb-10 bg-terms">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="offset-xl-1 col-xl-10 col-lg-12 col-md-12 col-12">
              <div class="text-center mb-0 px-md-8">
                <h1 class="text-purple display-4 fw-bold mb-4">
                  Terms of Service
                </h1>
                <p class="text-purple fs-4">
                  Please read this agreement carefully, as it contains important
                  information regarding your legal rights and remedies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-10 pb-8">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="offset-xl-1 col-xl-10 col-lg-12 col-md-12 col-12">
              <div class="text-start mb-0">
                <h1 class="text-purple2 fs-4 fw-bold mb-4">
                  Effective date: 23rd of March, 2021
                </h1>
              </div>
              <hr className="my-4" />
            </div>
          </div>

          <div class="row align-items-center">
            <div class="offset-xl-1 col-xl-10 col-lg-12 col-md-12 col-12">
              <div class="text-start mb-4 fs-4">
                <p>
                  Please read these Terms carefully. By using Smart Edu App or
                  signing up for an account, you’re agreeing to these Terms,
                  which will result in a legal agreement between you and Smart
                  Edu Inc. We’ll start with the basics, including a few
                  definitions that should help you understand these Terms. Smart
                  Edu App (SmartEdu Inc “we,” or “us”) is an all in one school
                  management system platform, (the “App”) that allows you to
                  manage your data base effectively, conduct E-learning
                  activities as well as E-Report system, provides you a school
                  website, among others.
                </p>
              </div>

              <hr className="my-8" />

              <div class="text-start mb-8">
                <h1 class="text-purple2 fs-3 fw-bold mb-4">1. Term</h1>

                <p className="fs-4">
                  When you sign up and agree to these Terms, the Agreement
                  between you and Smart Edu Inc is formed, and the term of the
                  Agreement (the “Term”) will begin. The Term will continue for
                  as long as you have an account with Smart Edu App.
                </p>
              </div>

              <div class="text-start mb-8">
                <h1 class="text-purple2 fs-3 fw-bold mb-4">2. Ownership</h1>

                <p className="fs-4">
                  The Smart Edu Inc is the sole owner of Smart Edu App and shall
                  have the right to exercise all incidents of ownership.
                </p>
              </div>

              <div class="text-start mb-8">
                <h1 class="text-purple2 fs-3 fw-bold mb-4">3. Eligibility</h1>

                <p className="fs-4">
                  In order to use the service, you must: .properly sign up with
                  Smart Edu Inc . Provide all necessary information needed for
                  proper registration .Be sure there is no outstanding payment
                  during reengagement of contract.
                  <br />
                  <br />
                  By using the Service, you represent and warrant that you meet
                  all the requirements listed above, and that you won’t use the
                  Service in a way that violates any laws or regulations. Note
                  that by representing and warranting, you are making a legally
                  enforceable promise. Smart Edu Inc may refuse service, close
                  accounts of any Members, and change eligibility requirements
                  at any time.
                </p>
              </div>

              <div class="text-start mb-8">
                <h1 class="text-purple2 fs-3 fw-bold mb-4">
                  4. Mode of payment and App Accessibility
                </h1>

                <p className="fs-4">
                  On the resumption of a new term, the App is open for 21days
                  and 70% ( seventy percent) payment is to be made to Smart Edu
                  Inc within this period. Access to the App will be blocked
                  after 21 days if this mode of payment is found to be violated
                  ( it will be reopened once payment is being made). The
                  remaining 30% ( thirty percent) is to be paid before the
                  commencement of the examinations for the term, if this is also
                  found to be violated, access to the App will be blocked till
                  payment is made. There shall be no refund once payment is
                  being made.
                </p>
              </div>

              <div class="text-start mb-8">
                <h1 class="text-purple2 fs-3 fw-bold mb-4">5. Price Review</h1>

                <p className="fs-4">
                  Most of our engagements and facilities are purchased in
                  dollars online, we may review the price of our product as a
                  result of increase or hike in dollar rate exchange and other
                  economic related issues.
                </p>
              </div>

              <div class="text-start mb-8">
                <h1 class="text-purple2 fs-3 fw-bold mb-4">6. Advert Zone</h1>

                <p className="fs-4">
                  Advert zone is a platform on Smart Edu App with which we put
                  up online ads. Smart Edu may send in adverts to contacts
                  existing in the data base, such as emails. And also display
                  ads on the App as part of our services.
                </p>
              </div>

              <div class="text-start mb-8">
                <h1 class="text-purple2 fs-3 fw-bold mb-4">7. Changes</h1>

                <p className="fs-4">
                  We may change any of the Terms by posting revised Terms on
                  your portal. The new Terms will be effective immediately upon
                  posting and apply to any continued or new use of the Service.
                  We may bring in new features and updates on the App at any
                  time.
                </p>
              </div>

              <div class="text-start mb-8">
                <h1 class="text-purple2 fs-3 fw-bold mb-4">
                  8. Account and Password
                </h1>

                <p className="fs-4">
                  You’re responsible for keeping your account name and password
                  confidential. You’re also responsible for any account that you
                  have access to and any activity occurring in such account
                  (other than activity that Smart Edu Inc is directly
                  responsible for). You’ll immediately notify us of any
                  unauthorized access or use of your accounts. We are not
                  responsible for any losses due to stolen or hacked passwords.
                  We don’t have access to your current password, and for
                  security reasons, we may only provide you with instructions on
                  how to reset your password. We strongly advise you you endure
                  your log in details are safe.
                </p>
              </div>

              <hr className="my-5" />

              <div class="text-start mt-4 fs-4">
                <p>
                Congratulations! You have reach the end, thank you for taking time to learn about Smart Edu Terms.
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Terms;
