import React, { useState, useEffect } from "react";

import { useNavigate, Link } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import Footer from "./Footer";

import { bounceInUp } from "react-animations";

import Radium, { StyleRoot } from "radium";

const Forum = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [school, setSchool] = useState("None");

  const Signup = (e) => {
    e.preventDefault();
    navigate("../signup/" + school + "/Gold Plus");
  };

  const [progress, setProgress] = useState(20);

  const styles = {
    bounce: {
      animation: "x 3s",
      animationName: Radium.keyframes(bounceInUp, "bounce"),
    },
  };

  const [search, setSearch] = useState(null);

  return (
    <div onLoad={() => setProgress(100)}>
      <LoadingBar
        color="#B53471"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <nav class="navbar navbar-expand-lg navbar-default shadow-none fixed-top py-2">
        <div className="container-fluid px-0">
          <Link to="/" class="navbar-brand">
            <img src="../assets/smlogo.png" width="110" alt="" />
          </Link>

          <div class="ms-16 d-block d-xl-none d-lg-none">
            <Link to="/cart" class="text-purple2 fs-10 fw-medium">
              <i class="bi bi-cart"></i>
            </Link>
          </div>

          <button
            class="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-default"
            aria-controls="navbar-default"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="icon-bar top-bar mt-0"></span>
            <span class="icon-bar middle-bar"></span>
            <span class="icon-bar bottom-bar"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbar-default">
            <ul class="navbar-nav">
              <li class="nav-item px-3">
                <Link class="nav-link" to="/products">
                  Products
                </Link>
              </li>
              <li class="nav-item px-3">
                <Link class="nav-link" to="/packages">
                  Packages
                </Link>
              </li>
              <li class="nav-item dropdown px-3">
                <a
                  class="nav-link fw-bold dropdown-toggle"
                  href="#"
                  id="navbarLanding"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Company
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-md shadow-none"
                  aria-labelledby="navbarLanding"
                >
                  <li>
                    <h4 class="dropdown-header">Company</h4>
                  </li>
                  <li>
                    <Link to="/about-us" class="dropdown-item">
                      About us
                    </Link>
                  </li>

                  <li>
                    <Link to="/contact-us" class="dropdown-item">
                      Contact us
                    </Link>
                  </li>

                  <li>
                    <Link to="/forum" class="dropdown-item">
                      Community Forum
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>

            <div class="ms-auto mt-3 mt-lg-0">
              <a
                class="btn btn-purple text-white shadow-lg"
                style={{ borderRadius: "30px" }}
                onClick={Signup}
              >
                Try us out for Free
              </a>
            </div>

            <div class="ms-lg-4 mt-3 ms-0 mt-lg-0">
              <Link
                to="/cart"
                class="btn fs-4 fw-medium shadow-lg"
                style={{
                  borderRadius: "30px",
                  background: "transparent",
                  borderColor: "#2c4f40",
                  color: "#2c4f40",
                }}
              >
                <i class="bi bi-cart"></i> Cart
              </Link>
            </div>
          </div>
        </div>
      </nav>

      <div class="pt-lg-20 bg-light-success pt-12 py-12 py-lg-0">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-10 col-12 pt-5 pt-lg-2 text-center text-lg-start">
              <div>
              <h2 class="display-5 mt-0 mb-8 fw-bold text-purple">
                  Community Forum
                </h2>

                <div class="mb-5">
                  <h1 class="fw-bold mb-4 fs-3">
                    Showing search results of{" "}
                    <span class="text-success">{search}</span>
                  </h1>
                </div>
                <div class="bg-white rounded-md-pill me-lg-10 shadow rounded-3">
                  <div class="p-md-2 p-4">
                    <form class="row g-1">
                      <div class="col-12 col-lg-8">
                        <div class="input-group mb-2 mb-md-0 border-md-0 border rounded-pill">
                          <span
                            class="input-group-text bg-transparent border-0 pe-0 ps-md-3 ps-md-0"
                            id="searchJob"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              fill="currentColor"
                              class="bi bi-search text-muted"
                              viewBox="0 0 16 16"
                            >
                              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                          </span>

                          <input
                            type="search"
                            class="form-control rounded-pill border-0 ps-3 form-focus-none"
                            placeholder="Search contents"
                            aria-label="Search contents"
                            aria-describedby="searchJob"
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>

                      <div class="col-12 col-lg-4 text-end d-grid">
                        <button
                          type="submit"
                          class="btn btn-success text-white rounded-pill"
                        >
                          Search
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-5 col-12 text-end offset-lg-1 pt-13 d-none d-lg-block">
              <StyleRoot>
                <div className="col-lg-2 col-2 mb-n14 ps-5 ms-20" style={styles.bounce}>
                  <div className="px-0 pt-2 pb-2 card card-hover shadow-lg rounded-circle bg-back">
                    <div className="text-center">
                      <img
                        src="../assets/images/favicon/smicon.png"
                        class="opacity-100"
                        width="80%"
                      />
                    </div>
                  </div>
                </div>
              </StyleRoot>
              <img
                src="../assets/images/background/globe.png"
                class="mt-n0 mt-lg-n16 opacity-100"
                width="90%"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="py-8">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-xl-9">
              <div class="row align-items-center mb-4">
                <div class="col">
                  <p class="mb-0">
                    You want to ask question? Click the "Ask Question" button
                    now.
                  </p>
                </div>
                <div class="col-auto">
                  <div class="d-flex ">
                    <button className="btn btn-purple text-white">
                      Ask Question
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 col-xl-3">
              <div class="card border mb-6 mb-md-0 shadow-none">
                <div class="card-header">
                  <h4 class="mb-0 fs-4">Public</h4>
                </div>

                <div class="card-body border-top py-3">
                  <div class="d-flex justify-content-between mt-2  text-dark">
                    <span class="mb-4 fs-5 fw-medium">Supports</span>
                    <span>343</span>
                  </div>

                  <div class="d-flex justify-content-between mt-2  text-dark">
                    <span class="mb-4 fs-5 fw-medium">All Questions</span>
                    <span>1023</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
};

export default Forum;
