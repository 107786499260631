import React, { useState, useEffect } from "react";

import { useNavigate, Link } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import Footer from "../Footer";

const Product = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [school, setSchool] = useState("None");

  const Signup = (e) => {
    e.preventDefault();
    navigate("../signup/" + school + "/Gold Plus");
  };

  const [progress, setProgress] = useState(20);

  return (
    <div onLoad={() => setProgress(100)}>
      <LoadingBar
        color="#B53471"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <nav class="navbar navbar-expand-lg navbar-default shadow-none fixed-top py-2">
        <div className="container-fluid px-0">
          <Link to="/" class="navbar-brand">
            <img src="../assets/smlogo.png" width="110" alt="" />
          </Link>

          <div class="ms-16 d-block d-xl-none d-lg-none">
            <Link to="/cart" class="text-purple2 fs-10 fw-medium">
              <i class="bi bi-cart"></i>
            </Link>
          </div>

          <button
            class="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-default"
            aria-controls="navbar-default"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="icon-bar top-bar mt-0"></span>
            <span class="icon-bar middle-bar"></span>
            <span class="icon-bar bottom-bar"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbar-default">
            <ul class="navbar-nav">
              <li class="nav-item px-3">
                <Link class="nav-link fw-bold" to="/products">
                  Products
                </Link>
              </li>
              <li class="nav-item px-3">
                <Link class="nav-link" to="/packages">
                  Packages
                </Link>
              </li>
              <li class="nav-item dropdown px-3">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarLanding"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Company
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-md shadow-none"
                  aria-labelledby="navbarLanding"
                >
                  <li>
                    <h4 class="dropdown-header">Company</h4>
                  </li>
                  <li>
                    <Link to="/about-us" class="dropdown-item">
                      About us
                    </Link>
                  </li>

                  <li>
                    <Link to="/contact-us" class="dropdown-item">
                      Contact us
                    </Link>
                  </li>

                  <li>
                    <Link to="/forum" class="dropdown-item">
                      Community Forum
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>

            <div class="ms-auto mt-3 mt-lg-0">
              <a
                class="btn btn-purple text-white shadow-lg"
                style={{ borderRadius: "30px" }}
                onClick={Signup}
              >
                Try us out for Free
              </a>
            </div>

            <div class="ms-lg-4 mt-3 ms-0 mt-lg-0">
              <Link
                to="/cart"
                class="btn fs-4 fw-medium shadow-lg"
                style={{
                  borderRadius: "30px",
                  background: "transparent",
                  borderColor: "#2c4f40",
                  color: "#2c4f40",
                }}
              >
                <i class="bi bi-cart"></i> Cart
              </Link>
            </div>
          </div>
        </div>
      </nav>

      <div class="pt-8 bg-back">
        <div class="container">
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12">
              <div class="text-center mb-8">
                <div>
                  <img
                    src="../../assets/images/background/star.svg"
                    width="100%"
                  />
                </div>
                <h1 class="display-lg-2 display-4 text-dark ls-sm">
                  Our Products{" "}
                </h1>
                <p class="text-dark fs-3 mb-4">
                  We are relentless to achieving the best school management
                  system.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-8">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 col-12 offset-lg-3">
              <div class="text-center mb-3">
                <span class="badge bg-light-primary rounded-pill fs-5 text-dark mb-4">
                  <span class="fw-bold">ChatRoom v1.2.0 </span>- coming soon
                </span>
                <div class="display-5 mb-5 text-dark">
                  <img
                    src="../../assets/images/brand/chatroom2.png"
                    width="12%"
                    alt="ChatRoom Logo"
                    style={{ filter: "brightness(10) invert(2)" }}
                  />{" "}
                  <span className="pt-8">ChatRoom</span>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-12">
              <div class="mt-4 mt-lg-0 px-lg-3 mb-10 mb-lg-0">
                <img
                  src="../../assets/images/background/chatroom.svg"
                  alt="..."
                  class="img-fluid w-100"
                />
              </div>
            </div>

            <div class="col-lg-6 col-md-12 col-12">
              <div class="pe-lg-3 ps-lg-3">
                <div class="mt-0">
                  <h3 className="display-5 mt-0 mb-5 fw-bold text-purple">
                    An advanced learning communication system.
                  </h3>

                  <div class="d-flex mb-4">
                    <div className="fs-10 text-purple">
                      <i class="bi bi-lightning-fill"></i>
                    </div>
                    <div class="ms-3">
                      <h3 class="mb-2">Fast and accessible </h3>
                      <p class="mb-0 fs-4">
                        Get connect with easy and swift access.
                      </p>
                    </div>
                  </div>

                  <div class="d-flex mb-4">
                    <div className="fs-10 text-purple">
                      <i class="bi bi-ui-checks-grid"></i>
                    </div>

                    <div class="ms-3">
                      <h3 class="mb-2">Advanced learning tools</h3>
                      <p class="mb-0 fs-4">
                        More excitment with integrated learning tools to make
                        learning fun!
                      </p>
                    </div>
                  </div>

                  <div class="d-flex ">
                    <div className="fs-10 text-purple">
                      <i class="bi bi-file-earmark-medical-fill"></i>
                    </div>

                    <div class="ms-3">
                      <h3 class="mb-2 lh-1">Quality Documentation </h3>
                      <p class="mb-0 fs-4">
                        Chat and conversation are well saved and can be exported
                        to accessible documents.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-8">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 col-12 offset-lg-3">
              <div class="text-center mb-3">
                <span class="badge bg-light-danger rounded-pill fs-5 text-dark mb-4">
                  <span class="fw-bold">Note Maker v1.2.0 </span>- under
                  development
                </span>
                <div class="display-5 mb-5 text-dark">
                  <img
                    src="../../assets/images/brand/noteM2.png"
                    width="12%"
                    alt="ChatRoom Logo"
                  />{" "}
                  <span className="pt-8">Note Maker</span>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-12">
              <div class="mt-4 mt-lg-0 px-lg-3 mb-10 mb-lg-0">
                <img
                  src="../../assets/images/background/NoteM.svg"
                  alt="..."
                  class="img-fluid w-100"
                />
              </div>
            </div>

            <div class="col-lg-6 col-md-12 col-12">
              <div class="pe-lg-3 ps-lg-3">
                <div class="mt-0">
                  <h3 className="display-5 mt-0 mb-5 fw-bold text-purple">
                    More flexible and easy way to make note.
                  </h3>

                  <div class="d-flex mb-4">
                    <div className="fs-10 text-purple">
                      <i class="bi bi-lightning-fill"></i>
                    </div>
                    <div class="ms-3">
                      <h3 class="mb-2">Fast and accessible </h3>
                      <p class="mb-0 fs-4">Easy to use, fast in production.</p>
                    </div>
                  </div>

                  <div class="d-flex mb-4">
                    <div className="fs-10 text-purple">
                      <i class="bi bi-share-fill"></i>
                    </div>

                    <div class="ms-3">
                      <h3 class="mb-2">Sharing made simplified</h3>
                      <p class="mb-0 fs-4">
                        Sharing of note has been made easily and simplified via
                        several methods.
                      </p>
                    </div>
                  </div>

                  <div class="d-flex ">
                    <div className="fs-10 text-purple">
                      <i class="bi bi-server"></i>
                    </div>

                    <div class="ms-3">
                      <h3 class="mb-2 lh-1">Cloud Storage </h3>
                      <p class="mb-0 fs-4">
                        Notes are well saved and can be exported to accessible
                        documents.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-8">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 col-12 offset-lg-3">
              <div class="text-center mb-3">
                <span class="badge bg-light-primary rounded-pill fs-5 text-dark mb-4">
                  <span class="fw-bold">SmartPay v1.2.0 </span>- under
                  maintainance
                </span>
                <div class="display-5 mb-5 text-dark">
                  <img
                    src="../../assets/images/brand/smpay.png"
                    width="42%"
                    alt="SmartPay Logo"
                  />{" "}
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-12">
              <div class="mt-4 mt-lg-0 px-lg-3 mb-10 mb-lg-0">
                <img
                  src="../../assets/images/background/smpay.svg"
                  alt="..."
                  class="img-fluid w-100"
                />
              </div>
            </div>

            <div class="col-lg-6 col-md-12 col-12">
              <div class="pe-lg-3 ps-lg-3">
                <div class="mt-0">
                  <h3 className="display-5 mt-0 mb-5 fw-bold text-purple">
                    Online Payment just got better, easier and faster.
                  </h3>

                  <div class="d-flex mb-4">
                    <div className="fs-10 text-purple">
                      <i class="bi bi-lightning-fill"></i>
                    </div>
                    <div class="ms-3">
                      <h3 class="mb-2">Fast, quick, seamless </h3>
                      <p class="mb-0 fs-4">
                        Payment now simplified with just a click of a button.
                      </p>
                    </div>
                  </div>

                  <div class="d-flex mb-4">
                    <div className="fs-10 text-purple">
                      <i class="bi bi-shield-fill-check"></i>
                    </div>

                    <div class="ms-3">
                      <h3 class="mb-2">Security is 100%</h3>
                      <p class="mb-0 fs-4">
                        Payment is secured by our SSL and cloudflare services.
                      </p>
                    </div>
                  </div>

                  <div class="d-flex ">
                    <div className="fs-10 text-purple">
                      <i class="bi bi-file-earmark-medical-fill"></i>
                    </div>

                    <div class="ms-3">
                      <h3 class="mb-2 lh-1">Quality Documentation </h3>
                      <p class="mb-0 fs-4">
                        Invoice and payment reciept is being issued always to
                        keep track of transactions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-8">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 col-12 offset-lg-3">
              <div class="text-center mb-3">
                <span class="badge bg-light-primary rounded-pill fs-5 text-dark mb-4">
                  <span class="fw-bold">Learning Hub v1.2.0 </span>- under
                  maintainance
                </span>
                <div class="display-5 mb-5 text-dark">
                  <img
                    src="../../assets/images/brand/learn.png"
                    width="12%"
                    alt="Learning Hub Logo"
                  />{" "}
                  <span className="pt-8">Learning Hub</span>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-12">
              <div class="mt-4 mt-lg-0 px-lg-3 mb-10 mb-lg-0">
                <img
                  src="../../assets/images/background/learn.svg"
                  alt="..."
                  class="img-fluid"
                />
              </div>
            </div>

            <div class="col-lg-6 col-md-12 col-12">
              <div class="pe-lg-3 ps-lg-3">
                <div class="mt-0">
                  <h3 className="display-5 mt-0 mb-5 fw-bold text-purple">
                    Learning now made easy to aceess and share
                  </h3>

                  <div class="d-flex mb-4">
                    <div className="fs-10 text-purple">
                      <i class="bi bi-lightning-fill"></i>
                    </div>
                    <div class="ms-3">
                      <h3 class="mb-2">Fast and accessible </h3>
                      <p class="mb-0 fs-4">Easy to use, fast in production.</p>
                    </div>
                  </div>

                  <div class="d-flex mb-4">
                    <div className="fs-10 text-purple">
                      <i class="bi bi-share-fill"></i>
                    </div>

                    <div class="ms-3">
                      <h3 class="mb-2">Sharing made simplified</h3>
                      <p class="mb-0 fs-4">
                        Sharing of activities, tasks, etc now easy and simplified.
                      </p>
                    </div>
                  </div>

                  <div class="d-flex ">
                    <div className="fs-10 text-purple">
                      <i class="bi bi-server"></i>
                    </div>

                    <div class="ms-3">
                      <h3 class="mb-2 lh-1">Cloud Storage </h3>
                      <p class="mb-0 fs-4">
                        Storage is guaranteed and security is top-notch.
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-12 bg-back">
        <div class="container">
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12">
              <div class="text-center mb-12">
                <span class="badge bg-light-success rounded-pill fs-5 text-dark mb-4">
                  <span class="fw-bold">Beta-v3.2.0 </span>- Just shipped
                  version
                </span>
                <h1 class="display-6 fw-bold text-dark ls-sm">
                  Choose the Best School Management Plan for Your School{" "}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-n8 pb-8">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-12 col-12">
              <div class="card border-0 mb-3">
                <div class="p-5 text-center">
                  <div class="mb-5">
                    <h2 class="fw-bold">
                      Starter{" "}
                      <span class="badge rounded-pill bg-danger fs-6">
                        37% Off Promo Sale
                      </span>
                    </h2>
                    <p class="mb-0">
                      To start exploring Smart Edu App today, you will get{" "}
                      <span class="text-dark fw-medium">Promo access</span> to
                      starter version. Limited for a term
                    </p>
                  </div>
                  <div class="d-flex justify-content-center mb-4">
                    <span class="h3 mb-0 fw-bold">₦</span>
                    <div class=" toggle-price-content display-5 text-dark">
                      500 <del className="text-danger">800</del>
                    </div>
                    <span
                      class="align-self-end mb-1 ms-2 toggle-price-content"
                      data-price-monthly="/Monthly"
                      data-price-yearly="/Yearly"
                    >
                      / Student
                    </span>
                  </div>
                  <div class="d-grid">
                    <Link
                      to="../signup/None/Starter"
                      class="btn"
                      style={{
                        background: "transparent",
                        borderColor: "#2c4f40",
                        color: "#2c4f40",
                      }}
                    >
                      Get Started Now
                    </Link>
                  </div>
                </div>
                <hr class="m-0" />
                <div class="p-5">
                  <h4 class="fw-bold mb-4">All core features, including:</h4>

                  <ul class="list-unstyled mb-0">
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Student management service</span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Teacher management service</span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Admin management service</span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>
                        <span class="fw-bold text-dark">5GB </span>storage
                      </span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>
                        <span class="fw-bold text-dark">E-Report </span> system{" "}
                        <span class="badge rounded-pill bg-primary">
                          (Limited version)
                        </span>
                      </span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Data file system</span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Access to support forums</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12 col-12">
              <div class="card border-0 mb-3">
                <div class="p-5 text-center">
                  <div class="mb-5">
                    <h2 class="fw-bold">Silver </h2>
                    <p class="mb-0">
                      Access Light version of the App and a little advance of{" "}
                      <span class="text-dark fw-medium">Starter Plan</span> with
                      some limited access. Renewed termly.
                    </p>
                  </div>
                  <div class="d-flex justify-content-center mb-4">
                    <span class="h3 mb-0 fw-bold">₦</span>
                    <div class=" toggle-price-content display-5 text-dark">
                      1000
                    </div>
                    <span
                      class="align-self-end mb-1 ms-2 toggle-price-content"
                      data-price-monthly="/Monthly"
                      data-price-yearly="/Yearly"
                    >
                      / Student
                    </span>
                  </div>
                  <div class="d-grid">
                    <Link
                      to="../signup/None/Silver"
                      class="btn"
                      style={{
                        background: "transparent",
                        borderColor: "#2c4f40",
                        color: "#2c4f40",
                      }}
                    >
                      Get Silver Access Now
                    </Link>
                  </div>
                </div>
                <hr class="m-0" />
                <div class="p-5">
                  <h4 class="fw-bold mb-4">Everything in Starter, plus:</h4>

                  <ul class="list-unstyled mb-0">
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>
                        <span class="fw-bold text-dark">E-Report </span> system{" "}
                        <span class="badge rounded-pill bg-primary">
                          (Limited version)
                        </span>
                      </span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>
                        <span class="fw-bold text-dark">E-Learning </span>{" "}
                        system{" "}
                        <span class="badge rounded-pill bg-primary">
                          (Limited version)
                        </span>
                      </span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>
                        <span class="fw-bold text-dark">10GB </span>storage
                      </span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>
                        <span class="fw-bold text-dark">Free </span> website
                        design{" "}
                      </span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Data file system</span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>12 / 5 support</span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Mild editing </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12 col-12">
              <div class="card border-0 mb-3 mb-lg-0">
                <div class="p-5 text-center">
                  <div class="mb-5">
                    <h2 class="fw-bold">Gold</h2>
                    <p class="mb-0">
                      Access all{" "}
                      <span class="text-dark fw-medium">
                        management services, E-report service, and E-learning
                        services.
                      </span>{" "}
                      Renewed termly.
                    </p>
                  </div>
                  <div class="d-flex justify-content-center mb-4">
                    <span class="h3 mb-0 fw-bold">₦</span>
                    <div class="toggle-price-content display-5 text-dark">
                      1200
                    </div>
                    <span
                      class="align-self-end mb-1 ms-2 toggle-price-content"
                      data-price-monthly="/Monthly"
                      data-price-yearly="/Yearly"
                    >
                      / Student
                    </span>
                  </div>
                  <div class="d-grid">
                    <Link to="../signup/None/Gold" class="btn"
                      style={{
                        background: "transparent",
                        borderColor: "#2c4f40",
                        color: "#2c4f40",
                      }}>
                      Get Gold Access
                    </Link>
                  </div>
                </div>
                <hr class="m-0" />
                <div class="p-5">
                  <h4 class="fw-bold mb-4">Everything in Starter, plus:</h4>

                  <ul class="list-unstyled mb-0">
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>
                        <span class="fw-bold text-dark">E-Report </span> system{" "}
                        <span class="badge rounded-pill bg-success">
                          (Full version)
                        </span>
                      </span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>
                        <span class="fw-bold text-dark">E-Learning </span>{" "}
                        system{" "}
                        <span class="badge rounded-pill bg-success">
                          (Full version)
                        </span>
                      </span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>
                        <span class="fw-bold text-dark">Unlimited </span>storage
                      </span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span class="fw-bold text-dark">Free </span> Website
                      design
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Data file system </span>{" "}
                      <span class="badge rounded-pill bg-success">
                        (Advanced)
                      </span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Bulk editing </span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>12 / 5 support</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12 col-12">
              <div class="card border-0 mb-3 mb-lg-0">
                <div class="p-5 text-center">
                  <div class="mb-5">
                    <h2 class="fw-bold">
                      Gold Plus{" "}
                      <span class="badge rounded-pill bg-danger fs-6">
                        Popular
                      </span>
                    </h2>
                    <p class="mb-0">
                      Access all{" "}
                      <span class="text-dark fw-medium">
                        management services, E-report service, and E-learning
                        services.
                      </span>{" "}
                      Renewed termly.
                    </p>
                  </div>
                  <div class="d-flex justify-content-center mb-4">
                    <span class="h3 mb-0 fw-bold">₦</span>
                    <div class="toggle-price-content display-5 text-dark">
                      1500
                    </div>
                    <span
                      class="align-self-end mb-1 ms-2 toggle-price-content"
                      data-price-monthly="/Monthly"
                      data-price-yearly="/Yearly"
                    >
                      / Student
                    </span>
                  </div>
                  <div class="d-grid">
                    <Link
                      to="../signup/None/Gold Plus"
                      class="btn btn-purple text-white"
                    >
                      Get Gold Plus Access
                    </Link>
                  </div>
                </div>
                <hr class="m-0" />
                <div class="p-5">
                  <h4 class="fw-bold mb-4">Everything in Starter, plus:</h4>

                  <ul class="list-unstyled mb-0">
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>
                        <span class="fw-bold text-dark">E-Report </span> system{" "}
                        <span class="badge rounded-pill bg-success">
                          (Full version)
                        </span>
                      </span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>
                        <span class="fw-bold text-dark">E-Learning </span>{" "}
                        system{" "}
                        <span class="badge rounded-pill bg-success">
                          (Full version)
                        </span>
                      </span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>
                        <span class="fw-bold text-dark">Unlimited </span>storage
                      </span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span class="fw-bold text-dark">Free </span> Website
                      design
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Data file system </span>{" "}
                      <span class="badge rounded-pill bg-success">
                        (Advanced)
                      </span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Bulk editing </span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>12 / 5 support</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12 col-12">
              <div class="card border-0 mb-3 mb-lg-0">
                <div class="p-5 text-center">
                  <div class="mb-5">
                    <h2 class="fw-bold">Diamond</h2>
                    <p class="mb-0">
                      Access to all of{" "}
                      <span class="text-dark fw-medium">Starter </span> and
                      <span class="text-dark fw-medium"> Gold </span> services,
                      plus some of the standalone packages. Renewed termly.
                    </p>
                  </div>
                  <div class="d-flex justify-content-center mb-4">
                    <span class="h3 mb-0 fw-bold">₦</span>
                    <div class="toggle-price-content display-5 text-dark">
                      2700
                    </div>
                    <span
                      class="align-self-end mb-1 ms-2 toggle-price-content"
                      data-price-monthly="/Monthly"
                      data-price-yearly="/Yearly"
                    >
                      / Student
                    </span>
                  </div>
                  <div class="d-grid">
                    <Link
                      to="../signup/None/Diamond"
                      class="btn"
                      style={{
                        background: "transparent",
                        borderColor: "#2c4f40",
                        color: "#2c4f40",
                      }}
                    >
                      Get Diamond Access
                    </Link>
                  </div>
                </div>
                <hr class="m-0" />
                <div class="p-5">
                  <h4 class="fw-bold mb-4">Everything in Gold, plus:</h4>

                  <ul class="list-unstyled mb-0">
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span className="text-dark fw-bold">Note Maker</span>{" "}
                      <span class="badge rounded-pill bg-success">
                        (Advanced)
                      </span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span className="text-dark fw-bold">Learning Hub </span>{" "}
                      <span class="badge rounded-pill bg-success">
                        (CBT system)
                      </span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>
                        <span class="fw-bold text-dark">Dedicated </span>
                        hardware
                      </span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>
                        <span class="fw-bold text-dark">99.9% uptime </span>
                        guarantee
                      </span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Advanced analytics </span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>3rd party integrations</span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>24 / 7 support</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12 col-12">
              <div class="card border-0 mb-3 mb-lg-0">
                <div class="p-5 text-center">
                  <div class="mb-5">
                    <h2 class="fw-bold">Diamond Plus</h2>
                    <p class="mb-0">
                      Access to all of{" "}
                      <span class="text-dark fw-medium">Starter </span> and
                      <span class="text-dark fw-medium"> Gold Plus </span>{" "}
                      services, plus some of the standalone packages. Renewed
                      termly.
                    </p>
                  </div>
                  <div class="d-flex justify-content-center mb-4">
                    <span class="h3 mb-0 fw-bold">₦</span>
                    <div class="toggle-price-content display-5 text-dark">
                      3000
                    </div>
                    <span
                      class="align-self-end mb-1 ms-2 toggle-price-content"
                      data-price-monthly="/Monthly"
                      data-price-yearly="/Yearly"
                    >
                      / Student
                    </span>
                  </div>
                  <div class="d-grid">
                    <Link
                      to="../signup/None/Diamond Plus"
                      class="btn"
                      style={{
                        background: "transparent",
                        borderColor: "#2c4f40",
                        color: "#2c4f40",
                      }}
                    >
                      Get Diamond Plus Access
                    </Link>
                  </div>
                </div>
                <hr class="m-0" />
                <div class="p-5">
                  <h4 class="fw-bold mb-4">Everything in Gold Plus, plus:</h4>

                  <ul class="list-unstyled mb-0">
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span className="text-dark fw-bold">Note Maker</span>{" "}
                      <span class="badge rounded-pill bg-success">
                        (Advanced)
                      </span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span className="text-dark fw-bold">Learning Hub </span>{" "}
                      <span class="badge rounded-pill bg-success">
                        (CBT system)
                      </span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>
                        <span class="fw-bold text-dark">Dedicated </span>
                        hardware
                      </span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>
                        <span class="fw-bold text-dark">99.9% uptime </span>
                        guarantee
                      </span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Advanced analytics </span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>3rd party integrations</span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>24 / 7 support</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Product;
