import ReactDOM from "react-dom";

import { HashRouter, Route, Routes } from "react-router-dom";

import Home from "./Home/Home";

import Signup from "./Signup";

import NoPage from "./NoPage";

import { configureStore } from '@reduxjs/toolkit';

import { Provider } from 'react-redux';

import useReducer from "./features/Users";

import "./index.css";

import Build from "./Build";

import Activate from "./Activate";

import Sync from "./Sync";

import Product from "./Product/Product";

import Package from "./Packages/Package";

import About from "./About/About";

import Contact from "./Contact/Contact";

import Forum from "./Forum";

import Terms from "./Terms";

import Privacy from "./Privacy";

import Carts from "./Carts";

const store = configureStore({
  reducer: {
    user: useReducer,
  }
})

export default function App() {
  return (
    <HashRouter>
      <Routes>
          <Route index element={<Home />} />
          <Route path="signup/:id/:plan" element={<Signup />} />
          <Route path="build/:id" element={<Build />} />
          <Route path="activate/:id" element={<Activate />} />
          <Route path="sync/:id" element={<Sync />} />
          <Route path="products" element={<Product />} />
          <Route path="packages" element={<Package />} />
          <Route path="about-us" element={<About />} />
          <Route path="contact-us" element={<Contact />} />
          <Route path="forum" element={<Forum />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy-policy" element={<Privacy />} />
          <Route path="cart" element={<Carts />} />
          <Route path="*" element={<NoPage />} />
      </Routes>
    </HashRouter>
  );
}

ReactDOM.render( <Provider store={store}><App /></Provider>, document.getElementById("root"));