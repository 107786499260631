import React from "react";

import Typed from "typed.js";

import { useState, useEffect, useRef } from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Carousel } from "react-responsive-carousel";

import { useNavigate, Link } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import { bounceInUp, bounceInLeft } from "react-animations";

import Radium, { StyleRoot } from "radium";

import Footer from "../Footer";

const Home = () => {
  const navigate = useNavigate();

  const el = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ["Upgrade", "Add Value", "Create an Edge", "Gain Money"], // Strings to display
      // Speed settings, try diffrent values untill you get good results
      startDelay: 200,
      typeSpeed: 200,
      backSpeed: 200,

      backDelay: 300,
      smartBackspace: true,
      loop: true,
      showCursor: true,
      cursorChar: "|",
    });

    // Destropying
    return () => {
      typed.destroy();
    };
  }, []);

  const [school, setSchool] = useState("None");

  const Signup = (e) => {
    e.preventDefault();
    navigate("../signup/" + school + "/Gold Plus");
  };

  const [progress, setProgress] = useState(20);

  const styles = {
    bounce: {
      animation: "x 3s",
      animationName: Radium.keyframes(bounceInUp, "bounce"),
    },
  };

  const styles1 = {
    bounce: {
      animation: "x 3s",
      animationName: Radium.keyframes(bounceInLeft, "bounce"),
    },
  };

  return (
    <div onLoad={() => setProgress(100)}>
      <LoadingBar
        color="#B53471"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <nav class="navbar navbar-expand-lg navbar-default shadow-none fixed-top py-2">
        <div className="container-fluid px-0">
          <Link to="/" class="navbar-brand">
            <img src="../assets/smlogo.png" width="110" alt="" />
          </Link>

          <div class="ms-16 d-block d-xl-none d-lg-none">
            <Link to="/cart" class="text-purple2 fs-10 fw-medium">
              <i class="bi bi-cart"></i>
            </Link>
          </div>

          <button
            class="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-default"
            aria-controls="navbar-default"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="icon-bar top-bar mt-0"></span>
            <span class="icon-bar middle-bar"></span>
            <span class="icon-bar bottom-bar"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbar-default">
            <ul class="navbar-nav">
              <li class="nav-item px-3">
                <Link class="nav-link" to="/products">
                  Products
                </Link>
              </li>
              <li class="nav-item px-3">
                <Link class="nav-link" to="/packages">
                  Packages
                </Link>
              </li>
              <li class="nav-item dropdown px-3">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarLanding"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Company
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-md shadow-none"
                  aria-labelledby="navbarLanding"
                >
                  <li>
                    <h4 class="dropdown-header">Company</h4>
                  </li>
                  <li>
                    <Link to="/about-us" class="dropdown-item">
                      About us
                    </Link>
                  </li>

                  <li>
                    <Link to="/contact-us" class="dropdown-item">
                      Contact us
                    </Link>
                  </li>

                  <li>
                    <Link to="/forum" class="dropdown-item">
                      Community Forum
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>

            <div class="ms-auto mt-3 mt-lg-0">
              <a
                class="btn btn-purple text-white shadow-lg"
                style={{ borderRadius: "30px" }}
                onClick={Signup}
              >
                Try us out for Free
              </a>
            </div>

            <div class="ms-lg-4 mt-3 ms-0 mt-lg-0">
              <Link
                to="/cart"
                class="btn fs-4 fw-medium shadow-lg"
                style={{
                  borderRadius: "30px",
                  background: "transparent",
                  borderColor: "#2c4f40",
                  color: "#2c4f40",
                }}
              >
                <i class="bi bi-cart"></i> Cart
              </Link>
            </div>
          </div>
        </div>
      </nav>

      <div class="pt-lg-10 pt-8">
        <div class="container">
          <div class="row mt-lg-0 mt-8 align-items-center">
            <div class="col-xl-6 col-lg-6 col-md-12">
              <div
                class="mb-4 mb-lg-4 min-vh-lg-50
                  bg-cover bg-white pt-6"
              >
                <div class="mb-0 text-center text-md-start">
                  <h1 class="display-2 mb-3 text-purple2 ls-sm">
                    <span class="headingTyped text-success" ref={el}></span>{" "}
                    with Smart Edu App{" "}
                  </h1>
                  <p class="mb-4 lead fs-3 text-dark px-0">
                    Smart Edu. App is a web App designed and developed by Smart
                    Edu Inc. It’s an App designed to suit the management
                    activities of your school. Smart Edu. App has many exciting
                    packages within and it is very affordable. Being a very easy
                    and interesting App it would so greatly increase your school
                    standard and help the school make money.
                  </p>

                  <div class="mb-6 mb-0">
                    <div class="align-items-center fs-3">
                      <div class="mb-2 text-dark">
                        <span class="me-2 ">
                          <i class="bi bi-check2-circle text-success"></i>
                        </span>
                        <span class="align-top">Reliable</span>
                      </div>
                      <div class="mb-2 text-dark">
                        <span class="me-2 ">
                          <i class="bi bi-lightning-fill text-warning"></i>
                        </span>
                        <span class="align-top">Efficient</span>
                      </div>
                      <div class="mb-2 text-dark">
                        <span class="me-2 ">
                          <i class="bi bi-shield-fill-check text-primary"></i>
                        </span>
                        <span class="align-top">Safe</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="offset-xl-1 col-xl-5 col-lg-6 col-md-12">
              <div class="mb-4 mb-lg-4">
                <StyleRoot>
                  <div
                    className="col-lg-6 mb-n10 ms-13 ms-lg-15"
                    style={styles.bounce}
                  >
                    <div className="p-4 card card-hover shadow-lg">
                      <div className="fs-6 fw-bold text-dark">
                        Uploading maths classwork
                        <div className="pt-2">
                          <div class="progress" style={{ height: "8px" }}>
                            <div
                              class="progress-bar"
                              role="progressbar"
                              style={{ width: "75%" }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              75%
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </StyleRoot>
                <img
                  src="../assets/images/background/banner1.png"
                  class=""
                  width="30%"
                />
              </div>

              <div class="mb-16 mb-lg-15 text-end">
                <img
                  src="../assets/images/background/banner.png"
                  class=""
                  width="35%"
                />
                <StyleRoot>
                  <div
                    className="col-lg-8 mt-n6 mt-lg-n12 me-12"
                    style={styles1.bounce}
                  >
                    <div className="p-2 px-2 px-lg-4 py-4 py-lg-4 p-lg-4 card card-hover shadow-lg">
                      <div className="fs-6 fw-bold text-dark text-start">
                        <i class="bi bi-patch-check-fill text-success fs-4"></i>{" "}
                        Success: You have successfully sent student results
                      </div>
                    </div>
                  </div>
                </StyleRoot>
              </div>

              <div class="mb-12 mb-lg-8 text-start">
                <div class="mt-n20 pt-6">
                  <img
                    src="../assets/images/background/banner1.svg"
                    class=""
                    width="90%"
                  />
                </div>
                <StyleRoot>
                  <div
                    className="col-lg-6 mt-n10 mt-lg-n12 me-20"
                    style={styles1.bounce}
                  >
                    <div className="p-2 px-2 px-lg-4 py-4 py-lg-4 p-lg-4 card card-hover shadow-lg">
                      <div className="fs-6 fw-bold text-dark">
                        1. What is electro-magnetism?
                        <div className="px-3 pt-2">
                          <div className="bg-light py-2"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </StyleRoot>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white pt-4">
        <div class="container-fluid">
          <div class="col-lg-6 col-12 offset-lg-3 text-center mb-6">
            <div>
              <img src="../../assets/images/background/star.svg" width="100%" />
            </div>
            <h1 class="display-5 mb-2 fw-bold text-purple2">
              All-In-One System
            </h1>
            <p className="fs-4 text-dark mt-3">
              We prepared the best school management plan for you to start. You
              will be able to adjust plans as you go - our custom-built
              algorithm will provide suggestions based on your usage.
            </p>
          </div>

          <div class="col-lg-10 col-12 offset-lg-1">
            <div className="card shadow">
              <div className="card-header bg-purple2 text-center">
                <h1 class="display-5 mb-2 text-purple2">Gold Plus Plan</h1>
              </div>
              <div className="card-body row align-items-center">
                <div className="col-auto px-lg-0 px-6">
                  <ul class="list-unstyled mb-0">
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Student management service</span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Teacher management service</span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Admin management service</span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>
                        <span class="fw-bold text-dark">Unlimited </span>storage
                      </span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>
                        <span class="fw-bold text-dark">
                          2 Parent Dispatch{" "}
                        </span>{" "}
                        system{" "}
                        <span class="badge rounded-pill bg-primary">
                          (SMS and Email Services)
                        </span>
                      </span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Data file system</span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Access to support forums</span>
                    </li>
                  </ul>
                </div>
                <div
                  class="col-auto ms-5 d-lg-flex d-none"
                  style={{ height: "200px" }}
                >
                  <div class="vr"></div>
                </div>

                <div className="col-auto text-center mb-5 mt-5 mb-lg-0 mt-lg-0 ms-lg-0 ms-8">
                  <div class="d-flex justify-content-center mb-4 text-purple">
                    <span class="h3 mb-0 fw-bold text-purple">₦</span>
                    <div class="toggle-price-content display-3">1500</div>
                    <span
                      class="align-self-end mb-1 ms-2 toggle-price-content fw-bolder"
                      data-price-monthly="/Monthly"
                      data-price-yearly="/Yearly"
                    >
                      / Student
                    </span>
                  </div>
                  <div class="ms-auto mt-3 mt-lg-0">
                    <a
                      class="btn btn-danger text-white"
                      style={{ borderRadius: "30px" }}
                      onClick={Signup}
                    >
                      Get Started Now
                    </a>
                  </div>
                </div>

                <div
                  class="col-auto me-10 d-lg-flex d-none"
                  style={{ height: "200px" }}
                >
                  <div class="vr"></div>
                </div>
                <div className="col-auto px-lg-0 px-6 d-none d-lg-block">
                  <ul class="list-unstyled mb-0">
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>
                        <span class="fw-bold text-dark">E-Report </span> system{" "}
                        <span class="badge rounded-pill bg-success">
                          (Full version)
                        </span>
                      </span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>
                        <span class="fw-bold text-dark">E-Learning </span>{" "}
                        system{" "}
                        <span class="badge rounded-pill bg-success">
                          (Full version)
                        </span>
                      </span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span class="fw-bold text-dark">Free </span> Website
                      design
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Data file system </span>{" "}
                      <span class="badge rounded-pill bg-success">
                        (Advanced)
                      </span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Bulk editing </span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>12 / 5 support</span>
                    </li>
                  </ul>
                </div>
                <div className="pt-lg-5 d-grid">
                  <hr className="my-2 text-purple2" />
                  <Link to="/packages" class="btn btn-link text-purple2 mb-2">
                    View full package details
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-12 offset-lg-3 text-center mb-4 pt-12">
            <h1 class="display-5 fw-bold text-purple2">
              What makes us standout:
            </h1>
          </div>

          <div class="row align-items-center">
            <div class="col-xl-6 col-lg-6 col-12 mb-4 text-center">
              <img
                src="../assets/images/about/fast.svg"
                alt="Gallery image 3"
                class="gallery__img"
                style={{ width: "90%" }}
              />
            </div>

            <div class="col-xl-4 col-lg-4 col-md-12 col-12 mb-3 offset-xl-1">
              <div class="mb-5 text-center">
                <h1 class="display-5 mb-2 fw-bold text-purple">
                  Fast and swift
                </h1>
                <p class="fs-4 mb-4 text-dark">
                  The speed we hav got it! Get activities done in no seconds!
                </p>
              </div>
            </div>
          </div>

          <div class="row align-items-center">
            <div class="col-xl-4 col-lg-4 col-md-12 col-12 mb-3 offset-xl-1 order-1">
              <div class="mb-5 text-center">
                <h1 class="display-5 mb-2 fw-bold text-purple">
                  Flexible and easy to use
                </h1>
                <p class="fs-4 mb-4 text-dark">
                  Try our custom-built admin dashboard. It’s intuitive, fast and
                  better.
                </p>
              </div>
            </div>

            <div class="col-xl-6 col-lg-6 col-12 mb-4 order-lg-2 offset-xl-1 text-center">
              <img
                src="../assets/images/about/easy.svg"
                alt="Gallery image 3"
                class="gallery__img"
                style={{ width: "90%" }}
              />
            </div>
          </div>

          <div class="row align-items-center">
            <div class="col-xl-6 col-lg-6 col-12 mb-4 text-center">
              <img
                src="../assets/images/about/secure.svg"
                alt="Gallery image 3"
                class="gallery__img"
                style={{ width: "90%" }}
              />
            </div>

            <div class="col-xl-4 col-lg-4 col-md-12 col-12 mb-3 offset-xl-1">
              <div class="mb-5 text-center">
                <h1 class="display-5 mb-2 fw-bold text-purple">
                  Security & back-up 100%
                </h1>
                <p class="fs-4 mb-4 text-dark">
                  All your information is been stored and saved on our cloud
                  system.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-md-4 pt-4 pb-16">
        <div class="container">
          <div class="row">
            <div class="offset-xl-2 col-xl-8 col-lg-12 col-md-12">
              <div class="mb-4 mb-xl-0 text-center">
                <span class="badge bg-light-success rounded-pill fs-5 text-dark">
                  <span class="fw-bold">Beta-v3.2.0 </span>- Just shipped
                  version
                </span>

                <h1 class="display-4 text-purple2 ls-sm mt-2 fw-bold">
                  Request Access for Product{" "}
                </h1>
                <p class="mb-6 fs-4 text-gray-700 px-md-8">
                  Smart Edu. App has been minimized to any user handling and
                  usage, and is on its own user friendly. It can be customized
                  to suit any school interest.
                </p>

                <form class="row px-lg-16 px-md-14" onSubmit={Signup}>
                  <div class="mb-3 col-md-8 col-12 ps-md-0">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter School Name"
                      required
                      onChange={(e) => setSchool(e.target.value)}
                    />
                  </div>

                  <div class="d-grid mb-3 col-md-4 col-12 ps-md-0">
                    <button class="btn btn-purple text-white" type="submit">
                      Request Access
                    </button>
                  </div>
                  <div class="text-start col-12 fw-medium ps-lg-0">
                    Rated 5 Stars
                    <span class="text-muted"> by over</span>
                    <span class="text-primary"> 2000+ Users</span>
                  </div>
                </form>
              </div>
            </div>

            <div class="offset-xl-1 col-xl-10 col-12 mt-12">
              <Carousel
                showThumbs={false}
                showIndicators={false}
                showStatus={false}
                autoPlay={true}
                interval={5000}
              >
                <div
                  class="card bg-gradient-mix-shade px-md-5 pt-md-5 px-4 pt-4 py-5
              rounded-3"
                >
                  <img
                    src="../../assets/images/background/analytics.png"
                    alt=""
                    class="rounded-3 mb-n6 img-fluid smooth-shadow-md"
                  />
                </div>
                <div
                  class="card bg-gradient-mix-shade px-md-5 pt-md-5 px-4 pt-4 py-5
              rounded-3"
                >
                  <img
                    src="../../assets/images/background/overview.png"
                    alt=""
                    class="rounded-3 mb-n6 img-fluid smooth-shadow-md"
                  />
                </div>

                <div
                  class="card bg-gradient-mix-shade px-md-5 pt-md-5 px-4 pt-4 py-5
              rounded-3"
                >
                  <img
                    src="../../assets/images/background/cbt.png"
                    alt=""
                    class="rounded-3 mb-n6 img-fluid smooth-shadow-md"
                  />
                </div>

                <div
                  class="card bg-gradient-mix-shade px-md-5 pt-md-5 px-4 pt-4 py-5
              rounded-3"
                >
                  <img
                    src="../../assets/images/background/sharing.png"
                    alt=""
                    class="rounded-3 mb-n6 img-fluid smooth-shadow-md"
                  />
                </div>

                <div
                  class="card bg-gradient-mix-shade px-md-5 pt-md-5 px-4 pt-4 py-5
              rounded-3"
                >
                  <img
                    src="../../assets/images/background/result.png"
                    alt=""
                    class="rounded-3 mb-n6 img-fluid smooth-shadow-md"
                  />
                </div>

                <div
                  class="card bg-gradient-mix-shade px-md-5 pt-md-5 px-4 pt-4 py-5
              rounded-3"
                >
                  <img
                    src="../../assets/images/background/send_result.png"
                    alt=""
                    class="rounded-3 mb-n6 img-fluid smooth-shadow-md"
                  />
                </div>

                <div
                  class="card bg-gradient-mix-shade px-md-5 pt-md-5 px-4 pt-4 py-5
              rounded-3"
                >
                  <img
                    src="../../assets/images/background/setup.png"
                    alt=""
                    class="rounded-3 mb-n6 img-fluid smooth-shadow-md"
                  />
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>

      <div class="py-10 bg-light-success">
        <div class="container">
          <div class="row">
            <div class="offset-lg-2 col-lg-8 col-md-12 col-12 text-center">
              <h2 class="display-4 mt-4 mb-3 text-purple fw-bold">
                Have questions?
              </h2>

              <p class="fs-4 text-purple2 px-lg-8 mb-6">
                We will assess your requirements and pick the best solution for
                you.
              </p>
              <a
                href="https://wa.link/fxpruf"
                class="btn btn-danger text-white px-8 shadow-lg"
                style={{ borderRadius: "30px" }}
              >
                <i class="bi bi-whatsapp"></i> Let's Chat
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="pb-2 pb-lg-2 pt-14">
        <div class="container">
          <div class="row">
            <div class="offset-xl-1 col-xl-10 col-12">
              <div class="row">
                <div class="col-lg-9 col-md-12 col-12 mb-5">
                  <h2 class="mb-3 display-4 fw-bold text-purple2">
                    What you stand to benefit
                  </h2>
                  <p class="fs-3 text-gray-700">
                    Smart Edu. Everything you need, and more.
                  </p>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="row">
                    <div class="col-md-6 col-12 pe-lg-6 mb-lg-6 mb-4">
                      <div
                        class="icon-shape icon-lg bg-light-danger h3 text-dark
                      rounded-3 mb-4"
                      >
                        <i class="bi bi-gift-fill"></i>
                      </div>

                      <h2 class="fw-bold">Free School Website</h2>

                      <p class="fs-4">
                        Get Free school website designed for your school once
                        you sign up with Smart Edu App.
                      </p>
                    </div>

                    <div class="col-md-6 col-12 pe-lg-6 mb-lg-6 mb-4">
                      <div
                        class="icon-shape icon-lg bg-light-warning h3 text-dark
                      rounded-3 mb-4"
                      >
                        <i class="bi bi-chat-fill"></i>
                      </div>

                      <h2 class="fw-bold">Live Chat Support</h2>

                      <p class="fs-4">
                        We are a step ahead to making sure we provide assistance
                        during usage of Smart Edu App.
                      </p>
                    </div>

                    <div class="col-md-6 col-12 pe-lg-6 mb-lg-6 mb-4">
                      <div
                        class="icon-shape icon-lg bg-light-primary h3 text-dark
                      rounded-3 mb-4"
                      >
                        <i class="bi bi-cloud-fill"></i>
                      </div>

                      <h2 class="fw-bold">Cloud storage</h2>

                      <p class="fs-4">
                        Data is safe and secured with Cloud storage medium.
                        Smart Edu App security is top-notch.
                      </p>
                    </div>

                    <div class="col-md-6 col-12 pe-lg-6 mb-lg-6 mb-4">
                      <div
                        class="icon-shape icon-lg bg-light-info h3 text-dark
                      rounded-3 mb-4"
                      >
                        <i class="bi bi-grid-fill"></i>
                      </div>

                      <h2 class="fw-bold">Physical Training</h2>

                      <p class="fs-4">
                        Smart Edu. Inc offers an IT expert that would come train
                        the school staffs physically on App usage.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="py-8 py-lg-8 bg-back">
        <div class="container">
          <div class="row mb-8 justify-content-center">
            <div class="col-lg-6 col-md-12 col-12 text-center">
              <div>
                <img
                  src="../../assets/images/background/star.svg"
                  width="100%"
                />
              </div>
              <span class="text-purple mb-3 d-block text-uppercase fw-semi-bold ls-xl">
                Testimonials
              </span>
              <h2 class="mb-2 display-4 fw-bold text-purple2">
                What clients are saying{" "}
              </h2>
              <p class="fs-3">Join the happy clients league today!</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-12 mb-4 mb-lg-0">
              <div class="card shadow-lg rounded-3">
                <div class="card-body p-4 p-md-8 text-center">
                  <i class="mdi mdi-48px mdi-format-quote-open text-light-success lh-1"></i>
                  <p class="fs-3 text-dark mt-3">
                    Without any doubts Smart Edu App has been wonderful to my
                    school. It has really added value to our school.
                  </p>
                </div>

                <div class="card-footer bg-success text-center border-top-0">
                  <div class="mt-n8">
                    <img
                      src="../../assets/images/avatar/madam1.jpg"
                      alt=""
                      class="rounded-circle border-success avatar-xl border border-4"
                    />
                  </div>
                  <div class="mt-2 text-white">
                    <h3 class="text-white mb-0">Mrs. A.E. Omiwale</h3>
                    <p class="text-white-50 mb-1">
                      Proprietress of Ebun Heritage School
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="card shadow-lg rounded-3">
                <div class="card-body p-4 p-md-8 text-center">
                  <i class="mdi mdi-48px mdi-format-quote-open text-light-info lh-1"></i>
                  <p class="fs-3 text-dark mt-3">
                    Ever since i have joined Smart Edu App it has been working
                    effectively and i have no reason to doubt their capability
                    and effectiveness.
                  </p>
                </div>

                <div class="card-footer bg-info text-center border-top-0">
                  <div class="mt-n8">
                    <img
                      src="../../assets/images/avatar/madam2.jpg"
                      alt=""
                      class="rounded-circle border-info avatar-xl border border-4"
                    />
                  </div>
                  <div class="mt-2 text-white">
                    <h3 class="text-white mb-0">Mrs. Olubunmi Ajiboso</h3>
                    <p class="text-white-50 mb-1">
                      Proprietress of Prime Solid School
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-8 py-10">
        <div class="container">
          <div class="row">
            <div class="offset-xl-1 col-xl-10 col-12">
              <div
                class="row px-3 pt-3 pb-0 pe-lg-0 pt-lg-5 align-items-center
              rounded-3 border smooth-shadow-sm"
              >
                <div class="col-lg-6 p-4 p-md-6 pt-lg-0">
                  <h1 class="display-4 fw-bold lh-1 mb-3 text-purple2">
                    Get early access
                  </h1>

                  <p class="fs-3 mb-5">
                    Sign up to the waitlist and stay in the loop, So you can
                    start exploring early
                  </p>

                  <form class="row" onSubmit={Signup}>
                    <div class="mb-3 col-md-7 col-12">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter School Name"
                        required
                        onChange={(e) => setSchool(e.target.value)}
                      />
                    </div>

                    <div class="d-grid mb-3 col-md-5 col-12 ps-md-0">
                      <button class="btn btn-purple text-white" type="submit">
                        Request Access
                      </button>
                    </div>
                  </form>
                </div>
                <div
                  class="col-lg-5 offset-lg-1 p-0 overflow-hidden
                rounded-end-md shadow-lg"
                >
                  <img
                    class="rounded-top-md card-img-size-600"
                    src="../../assets/images/background/overview.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
