import React, { useState, useEffect, useRef } from "react";

import { useParams } from "react-router-dom";

import Typed from "typed.js";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const Sync = () => {
  const { id } = useParams();

  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        "Preparing workspace",
        "Getting you ready",
        "Please wait",
        "We are with you",
      ], // Strings to display
      // Speed settings, try diffrent values untill you get good results
      startDelay: 300,
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 300,
      smartBackspace: true,
      loop: true,
      showCursor: true,
      cursorChar: "|",
    });

    // Destropying
    return () => {
      typed.destroy();
    };
  }, []);


  const [users, setUser] = useState({});

  const school = users.school;

  const fetchData = () => {
    axios({
      method: "post",
      url: "/v2/auths/",
      data: {
        token: id,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "failed") {
          toast.error("Error: Request Failed! Check your connection..");
        } else {
          setUser(view.user_data.data);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check your connection..");
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
    LoadStudent();
  }, [school]);

  const LoadStudent = () => {
    const load = "student";

    console.log(school);

    axios({
      method: "post",
      url: "/v2/sync/",
      data: {
        school,
        load,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "success") {
          LoadTeacher();
        } else {
          const interval = setInterval(() => {
            LoadStudent();
          }, 10000);
        }
      })
      .catch((err) => {
        const interval = setInterval(() => {
          LoadStudent();
        }, 10000);
        toast.error("Error: Request Failed! Check your connection..");
      });
  };

  const LoadTeacher = () => {
    const load = "teacher";

    axios({
      method: "post",
      url: "/v2/sync/",
      data: {
        school,
        load,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "success") {
          LoadAdmin();
        } else {
          const interval_ = setInterval(() => {
            LoadTeacher();
          }, 10000);
        }
      })
      .catch((err) => {
        const interval_ = setInterval(() => {
          LoadTeacher();
        }, 10000);
        toast.error("Error: Request Failed! Check your connection..");
      });
  };

  const LoadAdmin = () => {
    const load = "admin";

    axios({
      method: "post",
      url: "/v2/sync/",
      data: {
        school,
        load,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "success") {

          setTimeout(function () {
            window.location.href = "https://app.smartdung.com/"+view.str+"/admin/"+view.token;
          }, 10000);

        } else {
          const interval__ = setInterval(() => {
            LoadAdmin();
          }, 10000);
        }
      })
      .catch((err) => {
        const interval__ = setInterval(() => {
          LoadAdmin();
        }, 10000);
        toast.error("Error: Request Failed! Check your connection..");
      });
  };

  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-default shadow-none fixed-top">
        <div className="container-fluid px-0 pt-2">
          <a class="navbar-brand me-lg-8">
            <img src="../assets/smlogo.png" width="120" alt="" />
          </a>

          <div class="text-dark fs-5 d-block d-xl-none">Loading..</div>

          <div class="collapse navbar-collapse" id="navbar-default">
            <div class="ms-auto mt-3 mt-lg-0">
              <div class="text-dark fs-4">Loading..</div>
            </div>
          </div>
        </div>
      </nav>

      <div class="py-10 bg-white text-center">
        <div class="col-md-12 text-center py-20">
          <span class="loader"></span>
          <h1 class="display-6 fw-bold mb-3 text-dark ls-sm mt-8">
            <span class="headingTyped text-dark" ref={el}></span>
          </h1>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default Sync;
