import React, { useState, useEffect } from "react";

import { Link, useParams, useNavigate } from "react-router-dom";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import LoadingBar from "react-top-loading-bar";

import Footer from "./Footer";

const Carts = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const key_checker = localStorage.getItem("key");

  if (!key_checker) {
    const key = Math.random().toString(36).substr(2);

    localStorage.setItem("key", key);
  }

  const [carts, setCarts] = useState([{}]);

  const fetchData = () => {
    axios({
      method: "post",
      url: "/v2/cart/",
      data: {
        id: key_checker,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "failed") {
          toast.error("Error: Request Failed! Check your connection..");
        } else {
          setCarts(view);
        }
      })
      .catch((err) => {
        //toast.error("Error: Request Failed! Check your connection..");
      });
  };

  const Signup = (click) => {
    axios({
      method: "post",
      url: "/v2/carts/",
      data: { school: click, status: null },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        const view = response.data;
        if (response.data.message === "success") {
          navigate("../build/" + view.token);
        } else {
          toast.error("Failed: Oops! Please try again!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.", {
          hideProgressBar: true,
          draggable: true,
          position: "top-right",
          icon: true,
          autoClose: 6000,
        });
      });
  };

  const Delete = (click) => {
    axios({
      method: "post",
      url: "/v2/carts/",
      data: { school: click, status: "remove" },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        const view = response.data;
        if (response.data.message === "success") {
          fetchData();
          toast.success("Success: Item has been removed", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });
        } else {
          toast.error("Failed: Oops! Please try again!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.", {
          hideProgressBar: true,
          draggable: true,
          position: "top-right",
          icon: true,
          autoClose: 6000,
        });
      });
  };

  const [progress, setProgress] = useState(20);

  console.log(carts);

  const renderCart = () => {
    if (carts == "") {
      return (
        <>
          <div class="col-lg-12 col-md-12 col-12">
            <div class="text-center mb-0 px-md-8 align-items-center">
              <img
                src="../assets/images/background/cart.svg"
                className="d-lg-none"
                width="80%"
                alt=""
              />
              <div className="col-auto text-center d-none d-lg-block">
                <img
                  src="../assets/images/background/cart.svg"
                  width="35%"
                  alt=""
                />
              </div>
              <h1 class="text-purple2 display-4 fw-bold mb-4">
                Looks like the cart is empty!
              </h1>
              <p class="text-purple fs-4">
                Not a problem, let's find a plan that will fit your school best.
              </p>
            </div>
          </div>

          <div class="row gy-5 mt-2">
            <div class="col-lg-4 col-md-12 col-12">
              <div class="card shadow border-0 mb-3">
                <div class="p-5 text-center">
                  <div class="mb-5">
                    <h2 class="fw-bold">
                      Starter{" "}
                      <span class="badge rounded-pill bg-danger fs-6">
                        37% Off Promo Sale
                      </span>
                    </h2>
                    <p class="mb-0">
                      To start exploring Smart Edu App today, you will get{" "}
                      <span class="text-dark fw-medium">Promo access</span> to
                      starter version. Limited for a term
                    </p>
                  </div>
                  <div class="d-flex justify-content-center mb-4">
                    <span class="h3 mb-0 fw-bold">₦</span>
                    <div class=" toggle-price-content display-5 text-dark">
                      500 <del className="text-danger">800</del>
                    </div>
                    <span
                      class="align-self-end mb-1 ms-2 toggle-price-content"
                      data-price-monthly="/Monthly"
                      data-price-yearly="/Yearly"
                    >
                      / Student
                    </span>
                  </div>
                  <div class="d-grid">
                    <Link
                      to="../signup/None/Starter"
                      class="btn"
                      style={{
                        background: "transparent",
                        borderColor: "#2c4f40",
                        color: "#2c4f40",
                      }}
                    >
                      Get Started Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12 col-12">
              <div class="card shadow border-0 mb-3">
                <div class="p-5 text-center">
                  <div class="mb-5">
                    <h2 class="fw-bold">Silver </h2>
                    <p class="mb-0">
                      Access Light version of the App and a little advance of{" "}
                      <span class="text-dark fw-medium">Starter Plan</span> with
                      some limited access. Renewed termly.
                    </p>
                  </div>
                  <div class="d-flex justify-content-center mb-4">
                    <span class="h3 mb-0 fw-bold">₦</span>
                    <div class=" toggle-price-content display-5 text-dark">
                      1000
                    </div>
                    <span
                      class="align-self-end mb-1 ms-2 toggle-price-content"
                      data-price-monthly="/Monthly"
                      data-price-yearly="/Yearly"
                    >
                      / Student
                    </span>
                  </div>
                  <div class="d-grid">
                    <Link
                      to="../signup/None/Silver"
                      class="btn"
                      style={{
                        background: "transparent",
                        borderColor: "#2c4f40",
                        color: "#2c4f40",
                      }}
                    >
                      Get Silver Access Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12 col-12">
              <div class="card shadow border-0 mb-3 mb-lg-0">
                <div class="p-5 text-center">
                  <div class="mb-5">
                    <h2 class="fw-bold">Gold</h2>
                    <p class="mb-0">
                      Access all{" "}
                      <span class="text-dark fw-medium">
                        management services, E-report service, and E-learning
                        services.
                      </span>{" "}
                      Renewed termly.
                    </p>
                  </div>
                  <div class="d-flex justify-content-center mb-4">
                    <span class="h3 mb-0 fw-bold">₦</span>
                    <div class="toggle-price-content display-5 text-dark">
                      1200
                    </div>
                    <span
                      class="align-self-end mb-1 ms-2 toggle-price-content"
                      data-price-monthly="/Monthly"
                      data-price-yearly="/Yearly"
                    >
                      / Student
                    </span>
                  </div>
                  <div class="d-grid">
                    <Link
                      to="../signup/None/Gold"
                      class="btn"
                      style={{
                        background: "transparent",
                        borderColor: "#2c4f40",
                        color: "#2c4f40",
                      }}
                    >
                      Get Gold Access
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12 col-12">
              <div class="card shadow border-0 mb-3 mb-lg-0">
                <div class="p-5 text-center">
                  <div class="mb-5">
                    <h2 class="fw-bold">
                      Gold Plus{" "}
                      <span class="badge rounded-pill bg-danger fs-6">
                        Popular
                      </span>
                    </h2>
                    <p class="mb-0">
                      Access all{" "}
                      <span class="text-dark fw-medium">
                        management services, E-report service, and E-learning
                        services.
                      </span>{" "}
                      Renewed termly.
                    </p>
                  </div>
                  <div class="d-flex justify-content-center mb-4">
                    <span class="h3 mb-0 fw-bold">₦</span>
                    <div class="toggle-price-content display-5 text-dark">
                      1500
                    </div>
                    <span
                      class="align-self-end mb-1 ms-2 toggle-price-content"
                      data-price-monthly="/Monthly"
                      data-price-yearly="/Yearly"
                    >
                      / Student
                    </span>
                  </div>
                  <div class="d-grid">
                    <Link
                      to="../signup/None/Gold Plus"
                      class="btn btn-purple text-white"
                    >
                      Get Gold Plus Access
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12 col-12">
              <div class="card shadow border-0 mb-3 mb-lg-0">
                <div class="p-5 text-center">
                  <div class="mb-5">
                    <h2 class="fw-bold">Diamond</h2>
                    <p class="mb-0">
                      Access to all of{" "}
                      <span class="text-dark fw-medium">Starter </span> and
                      <span class="text-dark fw-medium"> Gold </span> services,
                      plus some of the standalone packages. Renewed termly.
                    </p>
                  </div>
                  <div class="d-flex justify-content-center mb-4">
                    <span class="h3 mb-0 fw-bold">₦</span>
                    <div class="toggle-price-content display-5 text-dark">
                      2700
                    </div>
                    <span
                      class="align-self-end mb-1 ms-2 toggle-price-content"
                      data-price-monthly="/Monthly"
                      data-price-yearly="/Yearly"
                    >
                      / Student
                    </span>
                  </div>
                  <div class="d-grid">
                    <Link
                      to="../signup/None/Diamond"
                      class="btn"
                      style={{
                        background: "transparent",
                        borderColor: "#2c4f40",
                        color: "#2c4f40",
                      }}
                    >
                      Get Diamond Access
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12 col-12">
              <div class="card shadow border-0 mb-3 mb-lg-0">
                <div class="p-5 text-center">
                  <div class="mb-5">
                    <h2 class="fw-bold">Diamond Plus</h2>
                    <p class="mb-0">
                      Access to all of{" "}
                      <span class="text-dark fw-medium">Starter </span> and
                      <span class="text-dark fw-medium"> Gold Plus </span>{" "}
                      services, plus some of the standalone packages. Renewed
                      termly.
                    </p>
                  </div>
                  <div class="d-flex justify-content-center mb-4">
                    <span class="h3 mb-0 fw-bold">₦</span>
                    <div class="toggle-price-content display-5 text-dark">
                      3000
                    </div>
                    <span
                      class="align-self-end mb-1 ms-2 toggle-price-content"
                      data-price-monthly="/Monthly"
                      data-price-yearly="/Yearly"
                    >
                      / Student
                    </span>
                  </div>
                  <div class="d-grid">
                    <Link
                      to="../signup/None/Diamond Plus"
                      class="btn"
                      style={{
                        background: "transparent",
                        borderColor: "#2c4f40",
                        color: "#2c4f40",
                      }}
                    >
                      Get Diamond Plus Access
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else if (carts) {
      return carts.map((cart, index) => {
        return (
          <>
            <div class="col-lg-6 col-md-12 col-12">
              <div class="card card-bordered border-dark mb-0 card-hover cursor-pointer shadow-sm">
                <div class="card-body">
                  <div>
                    <div class="row align-items-center">
                      <div class="col-md-5 mb-0 mb-md-0">
                        <img
                          src="../assets/images/background/cart_.svg"
                          className="d-none d-lg-block"
                          width="100%"
                          alt=""
                        />

                        <img
                          src="../assets/images/background/cart_.svg"
                          className="d-lg-none"
                          width="100%"
                          alt=""
                        />
                      </div>

                      <div class="col-md-7 mt-3 mt-xl-1">
                        <div class="d-flex justify-content-between mb-3">
                          <div>
                            <h3 class="mb-1 fs-3 text-purple2">
                              {cart.plan}

                              <span class="badge bg-light-primary text-dark fs-5 ms-2">
                                Pending
                              </span>
                            </h3>

                            <div className="mt-2">
                              <span className="fs-5 fw-bold text-purple">
                                School Name: {cart.school}{" "}
                              </span>
                            </div>

                            <div className="mt-2">
                              <span className="fs-5 fw-bold text-purple">
                                Population: {cart.numbers}{" "}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div class="d-md-flex justify-content-between align-items-center">
                          <div className="text-dark">
                            <span>
                              {cart.plan === "Gold Plus" ||
                              cart.plan === "Diamond Plus" ? (
                                <>Double Parent Dispatch</>
                              ) : (
                                <>Single Parent Dispatch</>
                              )}
                            </span>
                          </div>
                          <div>
                            <button
                              className="btn btn-light-danger btn-sm text-uppercase text-danger"
                              onClick={() => Delete(cart.school)}
                            >
                              <i class="bi bi-trash"></i>
                            </button>

                            <button
                              className="btn btn-light-success btn-sm text-uppercase text-success ms-2"
                              onClick={() => Signup(cart.school)}
                            >
                              Open
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  return (
    <div onLoad={() => setProgress(100)}>
      <LoadingBar
        color="#B53471"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <nav class="navbar navbar-expand-lg navbar-default shadow-none fixed-top py-3">
        <div className="container px-0">
          <Link to="/" class="navbar-brand">
            <img src="../assets/smlogo.png" width="120" alt="" />
          </Link>
        </div>
      </nav>

      {carts != "" ? (
        <div class="pt-lg-14 pt-14 pb-6 bg-back">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="text-center mb-0 px-md-8 align-items-center">
                  <h1 class="text-purple2 display-5 fw-bold mb-4">
                    You have pending order!
                  </h1>
                  <p class="text-dark fs-4">
                    Click on your order below to complete your purchase.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div class="pt-10 pb-12">
        <div class="container">
          <div class="row align-items-center">{renderCart()}</div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </div>
  );
};

export default Carts;
