import React, { useState, useEffect } from "react";

import { Link, useParams, useNavigate } from "react-router-dom";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const Build = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const { id } = useParams();

  const year = new Date().getFullYear();

  const [users, setUser] = useState({});

  const [payment, setPlan] = useState("FULL");

  const [dispatch, setDispatch] = useState("null");

  const plans = users.plan;

  useEffect(() => {
    if(plans === 'Gold Plus' || plans === 'Diamond Plus'){
      setDispatch('DOUBLE');
    }
    else{ 
      setDispatch('SINGLE');
    }
  }, [plans]);

  const fetchData = () => {
    axios({
      method: "post",
      url: "/v2/auths/",
      data: {
        token: id,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "failed") {
          toast.error("Error: Request Failed! Check your connection..");
        } else {
          setUser(view.user_data.data);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check your connection..");
      });
  };

  const Signup = (e) => {
    e.preventDefault();

    const school = users.school;

    const user = {
      payment,
      dispatch,
      school,
    };

    axios({
      method: "post",
      url: "/v2/build/",
      data: user,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        const view = response.data;
        if (response.data.message === "success") {
          toast.success("Success: Build was successfull!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-center",
            icon: true,
            autoClose: 6000,
          });

          navigate("../activate/" + id);
        } else {
          toast.error("Failed: Build Failed! Try Again!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.", {
          hideProgressBar: true,
          draggable: true,
          position: "top-right",
          icon: true,
          autoClose: 6000,
        });
      });
  };

  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-default shadow-none fixed-top">
        <div className="container-fluid px-0 pt-2">
          <Link to="/" class="navbar-brand me-lg-8">
            <img src="../assets/smlogo.png" width="120" alt="" />
          </Link>

          <div class="text-dark fs-5 d-block d-xl-none">Showing 2 of 3</div>

          <div class="collapse navbar-collapse" id="navbar-default">
            <ul class="navbar-nav">
              <li class="nav-item px-4">
                <Link to="/cart" class="nav-link">1. Cart</Link>
              </li>
              <li class="nav-item px-4">
                <a class="nav-link fw-bold">2. Build your trial</a>
              </li>
              <li class="nav-item px-4">
                <a class="nav-link">3. App Activation</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div class="py-xl-8 py-5">
        <div class="container-fluid align-items-center min-vh-lg-100">
          <div
            class="d-lg-flex align-items-center w-lg-100
          min-vh-lg-100 position-fixed-lg bg-cover bg-white"
            style={{
              backgroundImage: `url(../assets/images/background/back1.png)`,
            }}
          >
            <div class="col-lg-10 offset-lg-1 py-10 py-lg-8">
              <div class="text-dark">
                <h1 class="display-4 text-center fw-bold text-purple2">
                  Build your free trial
                </h1>
                <form className="row mt-6" onSubmit={Signup}>
                  <div class="col-lg-12 mb-5">
                    <div className="p-5 card bg-warning-50 rounded-3">
                      <div className="row d-flex align-items-center justify-content-between">
                        <div className="col-auto display-6 fw-bold mb-2">
                          {users.plan}
                        </div>

                        <div className="col-auto fs-4 mb-2">
                          Price is{" "}
                          <span className="fw-bolder">#{users.price}</span> per
                          child
                        </div>
                      </div>

                      <div className="fs-4 mt-4">
                        You have selected this package you can always change the
                        package later on once you completely sign up.
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 mb-5">
                    <div className="p-lg-5 p-3 pt-3 card bg-back rounded-3">
                      <div className="fs-4 fw-bold text-dark">
                        <span className="display-6 fw-bolder">1.</span> Choose
                        your post-trial billing
                      </div>

                      <div className="row d-flex align-items-center justify-content-between pt-6">
                        <div className="col-auto mb-3">
                          {payment === "PART" ? (
                            <button
                              type="button"
                              class="btn btn-white smooth-shadow-sm border border-dark mb-2 px-6 py-4 mb-2 fs-3"
                              onClick={() => setPlan("PART")}
                            >
                              Part Payment{" "}
                              <span class="badge bg-danger fs-6">Selected</span>
                            </button>
                          ) : (
                            <button
                              type="button"
                              class="btn btn-white smooth-shadow-sm mb-2 px-6 py-4 mb-2 fs-3"
                              onClick={() => setPlan("PART")}
                            >
                              <i class="fe fe-shopping-cart icon-shape icon-sm rounded-3 bg-light-success text-dark-success"></i>{" "}
                              Part Payment
                            </button>
                          )}
                        </div>

                        <div className="col-auto mb-3">
                          {payment === "FULL" ? (
                            <button
                              type="button"
                              class="btn btn-white smooth-shadow-sm border border-dark mb-2 px-6 py-4 mb-2 fs-3"
                              onClick={() => setPlan("FULL")}
                            >
                              Full Payment{" "}
                              <span class="badge bg-danger fs-6">Selected</span>
                            </button>
                          ) : (
                            <button
                              type="button"
                              class="btn btn-white smooth-shadow-sm mb-2 px-6 py-4 mb-2 fs-3"
                              onClick={() => setPlan("FULL")}
                            >
                              <i class="fe fe-shopping-cart icon-shape icon-sm rounded-3 bg-light-success text-dark-success"></i>{" "}
                              Full Payment
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 mb-5">
                    <div className="p-lg-5 p-2 pt-4 card bg-back rounded-3">
                      <div className="fs-4 fw-bold text-dark">
                        <span className="display-6 fw-bolder">2.</span>{" "}
                        Configure Dispatch Service type - {" "}
                        <span className="text-primary">
                          (
                          {dispatch === "SINGLE" ? (
                            <span>
                              The Plan you choose comes with Single Dispatch
                              Service
                            </span>
                          ) : (
                            <span>The Plan you choose comes with 
                              Double Dispatch Service</span>
                          )}
                          )
                        </span>
                      </div>

                      <div class="col-lg-12 mb-5 mt-5">
                        <div className="p-5 card bg-white rounded-3">
                          <div className="row d-flex align-items-center justify-content-between">
                            <div className="col-auto fs-3 fw-bold text-dark">
                              <i class="bi bi-person-fill icon-shape icon-lg rounded-circle bg-light-primary text-dark-primary"></i>{" "}
                              <span className="display-7 fw-bold">
                                Single Parent Dispatch
                              </span>
                            </div>

                            <div className="col-auto fs-5 mb-2"></div>
                          </div>

                          <div className="fs-4 mt-3 mb-3">
                            Student results sends to one-parent contact details,
                            i.e; (email and phone number).
                          </div>

                          {dispatch === "SINGLE" ? (
                            <div className="text-end">
                              <button
                                type="button"
                                class="btn btn-purple text-white mb-2"
                                onClick={() => setDispatch("SINGLE")}
                              >
                                Selected
                              </button>
                            </div>
                          ) : (
                            <div className="text-end">
                              <button
                                type="button"
                                class="btn btn-outline-dark mb-2"
                                onClick={() => setDispatch("SINGLE")}
                              >
                                Select
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      <div class="col-lg-12 mb-5">
                        <div className="p-5 card bg-white rounded-3">
                          <div className="row d-flex align-items-center justify-content-between">
                            <div className="col-auto fs-3 fw-bold text-dark">
                              <i class="bi bi-people-fill icon-shape icon-lg rounded-circle bg-light-primary text-dark-primary"></i>{" "}
                              <span className="display-7 fw-bold">
                                Double Parent Dispatch
                              </span>
                            </div>

                            <div className="col-auto fs-5 mb-2"></div>
                          </div>

                          <div className="fs-4 mt-3 mb-3">
                            Student results sends to two-parent contact details,
                            i.e; (email and phone number).
                          </div>

                          {dispatch === "DOUBLE" ? (
                            <div className="text-end">
                              <button
                                type="button"
                                class="btn btn-purple text-white mb-2"
                                onClick={() => setDispatch("DOUBLE")}
                              >
                                Selected
                              </button>
                            </div>
                          ) : (
                            <div className="text-end">
                              <button
                                type="button"
                                class="btn btn-outline-dark mb-2"
                                onClick={() => setDispatch("DOUBLE")}
                              >
                                Select
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 text-center mb-5">
                    <button
                      type="submit"
                      class="btn btn-purple btn-lg text-white mb-2"
                      style={{ borderRadius: "30px" }}
                    >
                      Continue to "App Activation"{" "}
                      <i class="bi bi-arrow-right"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default Build;
