import React, { useState, useEffect } from "react";

import { useNavigate, Link } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import Footer from "./Footer";

const Privacy = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [school, setSchool] = useState("None");

  const Signup = (e) => {
    e.preventDefault();
    navigate("../signup/" + school + "/Gold Plus");
  };

  const [progress, setProgress] = useState(20);

  return (
    <div onLoad={() => setProgress(100)}>
      <LoadingBar
        color="#B53471"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <nav class="navbar navbar-expand-lg navbar-default shadow-none fixed-top py-2">
        <div className="container-fluid px-0">
          <Link to="/" class="navbar-brand">
            <img src="../assets/smlogo.png" width="110" alt="" />
          </Link>

          <div class="ms-16 d-block d-xl-none d-lg-none">
            <Link to="/cart" class="text-purple2 fs-10 fw-medium">
              <i class="bi bi-cart"></i>
            </Link>
          </div>

          <button
            class="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-default"
            aria-controls="navbar-default"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="icon-bar top-bar mt-0"></span>
            <span class="icon-bar middle-bar"></span>
            <span class="icon-bar bottom-bar"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbar-default">
            <ul class="navbar-nav">
              <li class="nav-item px-3">
                <Link class="nav-link" to="/products">
                  Products
                </Link>
              </li>
              <li class="nav-item px-3">
                <Link class="nav-link fw-bold" to="/packages">
                  Packages
                </Link>
              </li>
              <li class="nav-item dropdown px-3">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarLanding"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Company
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-md shadow-none"
                  aria-labelledby="navbarLanding"
                >
                  <li>
                    <h4 class="dropdown-header">Company</h4>
                  </li>
                  <li>
                    <Link to="/about-us" class="dropdown-item">
                      About us
                    </Link>
                  </li>

                  <li>
                    <Link to="/contact-us" class="dropdown-item">
                      Contact us
                    </Link>
                  </li>

                  <li>
                    <Link to="/forum" class="dropdown-item">
                      Community Forum
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>

            <div class="ms-auto mt-3 mt-lg-0">
              <a
                class="btn btn-purple text-white shadow-lg"
                style={{ borderRadius: "30px" }}
                onClick={Signup}
              >
                Try us out for Free
              </a>
            </div>

            <div class="ms-lg-4 mt-3 ms-0 mt-lg-0">
              <Link
                to="/cart"
                class="btn fs-4 fw-medium shadow-lg"
                style={{
                  borderRadius: "30px",
                  background: "transparent",
                  borderColor: "#2c4f40",
                  color: "#2c4f40",
                }}
              >
                <i class="bi bi-cart"></i> Cart
              </Link>
            </div>
          </div>
        </div>
      </nav>

      <div class="pt-lg-20 pt-18 pb-10 bg-terms">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="offset-xl-1 col-xl-10 col-lg-12 col-md-12 col-12">
              <div class="text-center mb-0 px-md-8">
                <h1 class="text-purple display-4 fw-bold mb-4">
                  Privacy Policy
                </h1>
                <p class="text-purple fs-4">
                  Please read this agreement carefully, as it contains important
                  information regarding your legal rights and remedies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-10 pb-8">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="offset-xl-1 col-xl-10 col-lg-12 col-md-12 col-12">
              <div class="text-start mb-0">
                <h1 class="text-purple2 fs-4 fw-bold mb-4">
                  Effective date: 23rd of March, 2021
                </h1>
              </div>
              <hr className="my-4" />
            </div>
          </div>

          <div class="row align-items-center">
            <div class="offset-xl-1 col-xl-10 col-lg-12 col-md-12 col-12">
              <div class="text-start mb-4 fs-4">
                <p>
                  Smart Edu Inc takes data privacy seriously. This privacy
                  policy explains who we are, how we collect, share and use
                  Personal Information, and how you can exercise your privacy
                  rights.
                  <br />
                  <br />
                  We recommend that you read this privacy policy in full to
                  ensure you are fully informed. If you have any questions or
                  concerns about our use of your Personal Information, then
                  please contact us using the contact details provided below.
                </p>
              </div>

              <hr className="my-8" />

              <div class="text-start mb-8">
                <h1 class="text-purple2 fs-3 fw-bold mb-4">1. The Basics</h1>

                <p className="fs-4">
                  <b>A. About Us:</b> Smart Edu Inc is a school management
                  system structured to effectively provide accurate and swift
                  services for schools management.
                  <br />
                  <br />
                  Our Service enables our users to, among other things, conduct
                  E- Learning operations, carry out E- Report activities get
                  Websites and website management services. We also provide
                  other related services, such as real-time data storage ,E-
                  payment platform and many more.
                  <br />
                  <br />
                  <b>B. Key Terms:</b> Member" means any person or entity that
                  is registered with us to use the service.
                  <br />
                  <br />
                  Personal Information" means any information that identifies or
                  can be used to identify an individual directly or indirectly.
                  Examples of Personal Information include, but are not limited
                  to, first and last name, date of birth, email address,
                  gender., or other demographic information.
                </p>
              </div>

              <div class="text-start mb-8">
                <h1 class="text-purple2 fs-3 fw-bold mb-4">
                  2. Privacy for Members
                </h1>

                <p className="fs-4">
                  This section applies to the Personal Information we collect
                  and process from a Member or potential Member through the
                  provision of the Service.
                </p>

                <p className="fs-4">
                  <b>A. Information We Collect:</b> The Personal Information
                  that we collect depends on the context of your interactions
                  with Smart Edu Inc. However, the Personal Information we
                  collect broadly falls into the following categories:
                  <br />
                  <br />
                  (i) Information you provide to us: You (or your organization)
                  may provide certain Personal Information to us when you sign
                  up for a Smart Edu App account and use the Service, consult with
                  our customer service team, send us an email. This information
                  may include: Business contact information (such as your name,
                  job title, organization, location, phone number, email
                  address, and country); Marketing information (such as your
                  contact preferences); Account log-in credentials (such as your
                  email address or username and password when you sign up for an
                  account with us); Troubleshooting and support data (which is
                  data you provide or we otherwise collect in connection with
                  support queries we receive from you. This may include contact,
                  the content of your chats and other communications with us.
                  <br />
                  <br />
                  Payment information (Including your bank name and account
                  number)
                  <br />
                  <br />
                  (ii) Information we collect automatically Device information:
                  We collect information about the device and applications you
                  use to access the Service, such as your IP address, your
                  operating system, your browser ID, viewfinder size, and other
                  information about your system and connection, your mobile
                  device’s operating system or platform, the type of mobile
                  device you use, your mobile device’s name and unique device
                  ID.
                  <br />
                  <br />
                  <b>B. Use of Personal Information:</b> We may use the Personal
                  Information we collect or receive about you in reliance on our
                  legitimate interests for the following purposes:
                  <br />
                  <br />
                  - To ensure compliance with our Standard Terms of Use and
                  applicable law. This may include utilizing usage data and
                  developing tools and algorithms that help us prevent
                  violations.
                  <br />
                  <br />
                  - To protect the rights and safety of Members, third parties
                  <br />
                  <br />
                  - To provide, support and improve the Service. For example,
                  this may include sharing your information with third parties
                  in order to provide and support our Service or to make certain
                  features of the Service available to our Members. When we
                  share Personal Information with third parties, we take steps
                  to protect your information in a manner that is consistent
                  with applicable privacy laws.
                  <br />
                  <br />
                  - To carry out other legitimate business purposes, as well,
                  such as the Advert zone platform on the App. ( This is solely
                  sending adverts to Email addresses given to us.)
                  <br />
                  <br />
                  - To respond to your online inquiries and requests, and to
                  provide you with information and access to resources or
                  services that you have requested from us.
                  <br />
                  <br />
                  - To manage the Smart Edu Sites and system administration and
                  security.
                  <br />
                  <br />
                </p>
              </div>

              <div class="text-start mb-8">
                <h1 class="text-purple2 fs-3 fw-bold mb-4">
                  3. General Information
                </h1>

                <p className="fs-4">
                  <b>A. How We Share Information:</b> We may share and disclose
                  your Personal Information with our subsidiaries or Affiliates
                  and third parties.
                  <br />
                  <br />
                  (i) Our service providers: Sometimes, we share your
                  information with our third-party service providers working on
                  our behalf for the purposes described in this privacy policy.
                  For example, companies we’ve hired to help us provide and
                  support our Service or assist in protecting and securing our
                  systems and services and other business-related functions.
                  Other examples include analyzing data, hosting data, engaging
                  technical support for our Service, processing payments, and
                  delivering content. In connection to our services, we also use
                  a third party system Paystack for online payment on Smart Edu
                  App.
                </p>
              </div>

              <div class="text-start mb-8">
                <h1 class="text-purple2 fs-3 fw-bold mb-4">4. Our Security</h1>

                <p className="fs-4">
                  We take appropriate and reasonable technical and
                  organizational measures designed to protect Personal
                  Information from cases of loss, misuse, unauthorized access,
                  disclosure, alteration, and destruction, taking into account
                  the risks involved in the processing and the nature of the
                  Personal Information. If you have any questions about the
                  security of your Personal Information, you may contact us .
                  <br />
                  <br />
                  Smart Edu App accounts require a username and password to log
                  in. Members must keep their username and password secure, and
                  never disclose it to a third party. Because the information in
                  a Member’s Smart Edu App account is private. We cannot resend
                  forgotten passwords, We will only provide Members with
                  instructions on how to reset them.
                </p>
              </div>

              <div class="text-start mb-8">
                <h1 class="text-purple2 fs-3 fw-bold mb-4">
                  5. Changes to this Policy
                </h1>

                <p className="fs-4">
                  We may change this privacy policy at any time and from time to
                  time. The most recent version of the privacy policy is
                  reflected by the version date located at the top of this
                  privacy policy. All updates and amendments are effective
                  immediately upon notice, which we may give by any means,
                  including, but not limited to, by posting a revised version of
                  this privacy policy or other notice on the Smart Edu Websites
                  or Web application. We encourage you to review this privacy
                  policy often to stay informed of changes that may affect you.
                  Our electronically or otherwise properly stored copies of this
                  privacy policy are each deemed to be the true, complete,
                  valid, authentic, and enforceable copy of the version of this
                  privacy policy that was in effect on each respective date you
                  visited the Smart Edu App.
                </p>
              </div>

              <hr className="my-5" />

              <div class="text-start mt-4 fs-4">
                <p>
                  Congratulations! You have reach the end, thank you for taking
                  time to learn about Smart Edu Privacy Policy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Privacy;
