import React, { useState, useEffect } from "react";

import { useNavigate, Link } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import Footer from "../Footer";

import { bounceInUp } from "react-animations";

import Radium, { StyleRoot } from "radium";

const About = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [school, setSchool] = useState("None");

  const Signup = (e) => {
    e.preventDefault();
    navigate("../signup/" + school + "/Gold Plus");
  };

  const [progress, setProgress] = useState(20);

  const styles = {
    bounce: {
      animation: "x 3s",
      animationName: Radium.keyframes(bounceInUp, "bounce"),
    },
  };

  return (
    <div onLoad={() => setProgress(100)}>
      <LoadingBar
        color="#B53471"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <nav class="navbar navbar-expand-lg navbar-default shadow-none fixed-top py-2">
        <div className="container-fluid px-0">
          <Link to="/" class="navbar-brand">
            <img src="../assets/smlogo.png" width="110" alt="" />
          </Link>

          <div class="ms-16 d-block d-xl-none d-lg-none">
            <Link to="/cart" class="text-purple2 fs-10 fw-medium">
              <i class="bi bi-cart"></i>
            </Link>
          </div>

          <button
            class="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-default"
            aria-controls="navbar-default"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="icon-bar top-bar mt-0"></span>
            <span class="icon-bar middle-bar"></span>
            <span class="icon-bar bottom-bar"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbar-default">
            <ul class="navbar-nav">
              <li class="nav-item px-3">
                <Link class="nav-link" to="/products">
                  Products
                </Link>
              </li>
              <li class="nav-item px-3">
                <Link class="nav-link" to="/packages">
                  Packages
                </Link>
              </li>
              <li class="nav-item dropdown px-3">
                <a
                  class="nav-link fw-bold dropdown-toggle"
                  href="#"
                  id="navbarLanding"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Company
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-md shadow-none"
                  aria-labelledby="navbarLanding"
                >
                  <li>
                    <h4 class="dropdown-header">Company</h4>
                  </li>
                  <li>
                    <Link to="/about-us" class="dropdown-item">
                      About us
                    </Link>
                  </li>

                  <li>
                    <Link to="/contact-us" class="dropdown-item">
                      Contact us
                    </Link>
                  </li>

                  <li>
                    <Link to="/forum" class="dropdown-item">
                      Community Forum
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>

            <div class="ms-auto mt-3 mt-lg-0">
              <a
                class="btn btn-purple text-white shadow-lg"
                style={{ borderRadius: "30px" }}
                onClick={Signup}
              >
                Try us out for Free
              </a>
            </div>

            <div class="ms-lg-4 mt-3 ms-0 mt-lg-0">
              <Link
                to="/cart"
                class="btn fs-4 fw-medium shadow-lg"
                style={{
                  borderRadius: "30px",
                  background: "transparent",
                  borderColor: "#2c4f40",
                  color: "#2c4f40",
                }}
              >
                <i class="bi bi-cart"></i> Cart
              </Link>
            </div>
          </div>
        </div>
      </nav>

      <div class="pt-14 bg-white">
        <div class="container">
          <div class="row align-items-center mb-5">
            <div class="col-xl-6 col-lg-6 col-12">
              <div class="mb-5">
                <h1 class="display-4 mb-4 fw-bold text-purple2">
                  Join the <span className="text-success">league</span>, we’re
                  growing fast!
                </h1>
                <p class="fs-3 mb-4 pe-xl-4">
                  Smart Edu Inc. is an Edu-Tech company one of its kind! We are
                  100% committed to providing the best, effective, accurate and
                  swift services for school management. We are also passionate
                  about learning of the young champs! So we are working
                  tiredlessly to ensure that education becomes simplified as
                  possible.
                </p>

                <p class="fs-3 mb-4 pe-xl-4">
                  Our Service enables our users to, among other things, conduct
                  E- Learning operations, carry out E- Report activities, get
                  websites and website management services. We also provide
                  other related services, such as real-time data storage, E-
                  payment platform and many more.
                </p>

                <p class="mt-4 mb-0">
                  1 million school accross{" "}
                  <a className="text-primary fw-medium">Nigeria</a> and as many
                  accross <a className="text-primary fw-medium">Africa</a> is
                  our goal.
                </p>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-12">
              <div class="row">
                <div class="col-md-4 col-4 px-1">
                  <div
                    class="bg-cover rounded-3 mb-2 h-12rem"
                    style={{
                      backgroundImage: "url(../assets/images/about/1.jpg)",
                    }}
                  ></div>
                  <div
                    class="bg-cover rounded-3 mb-2 h-18rem"
                    style={{
                      backgroundImage: "url(../assets/images/about/2.jpg)",
                    }}
                  ></div>
                </div>

                <div class="col-md-4 col-4 px-1">
                  <div
                    class="bg-cover rounded-3 mb-2 h-18rem"
                    style={{
                      backgroundImage: "url(../assets/images/about/3.jpg)",
                    }}
                  ></div>
                  <div
                    class="bg-cover rounded-3 mb-2 h-18rem"
                    style={{
                      backgroundImage: "url(../assets/images/about/4.jpg)",
                    }}
                  ></div>
                </div>

                <div class="col-md-4 col-4 px-1">
                  <div
                    class="bg-cover rounded-3 mb-2 h-13rem"
                    style={{
                      backgroundImage: "url(../assets/images/about/5.jpg)",
                    }}
                  ></div>
                  <div
                    class="bg-cover rounded-3 mb-2 h-13rem"
                    style={{
                      backgroundImage: "url(../assets/images/about/6.jpg)",
                    }}
                  ></div>
                  <div
                    class="bg-cover rounded-3 mb-2 h-13rem"
                    style={{
                      backgroundImage: "url(../assets/images/about/7.jpg)",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-12 offset-lg-3 text-center mb-12 mb-lg-20 pt-12">
            <h1 class="display-4 fw-bold text-purple2">Our global reach</h1>
          </div>

          <div class="row align-items-center pt-10 pt-lg-10">
            <div class="col-xl-5 col-lg-5 col-12 mb-4 text-center">
              <StyleRoot>
                <div className="col-lg-8 col-6 mb-n20" style={styles.bounce}>
                  <div className="p-4 card card-hover shadow-lg" style={{ borderRadius: "30px" }}>
                    <div className="fs-4 fw-bold text-dark">
                      <i class="bi bi-geo-alt-fill text-purple2 fs-3"></i>{" "}
                      connecting to your school.
                    </div>
                  </div>
                </div>
              </StyleRoot>
              <img
                src="../assets/images/background/globe.png"
                class="mt-n10 mt-lg-n16 gallery__img"
                width="90%"
              />
            </div>

            <div class="col-xl-6 col-lg-6 col-md-12 col-12 mb-3 offset-xl-1 mt-lg-n20">
              <div class="ps-lg-7">
                <span
                  class=" text-dark-50 ls-md text-uppercase
                fw-semi-bold"
                >
                  Geo-Location
                </span>
                <h2 class="display-5 mt-4 mb-3 fw-bold text-purple">
                  Wide range network
                </h2>
                <div className="fs-3 mb-lg-20">
                  We have a wide coverage of network and our support team
                  coverage is global.{" "}
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-12 offset-lg-3 text-center mb-12 mb-lg-10 pt-12">
            <h1 class="display-4 fw-bold text-purple2">We are relentless</h1>
          </div>

          <div class="row align-items-center pt-10 pt-lg-10">
            <div class="col-xl-5 col-lg-5 col-12 mb-4 text-center">
              <img
                src="../assets/images/about/innovate.svg"
                class="mt-n10 mt-lg-n16 gallery__img"
                width="100%"
              />
            </div>

            <div class="col-xl-6 col-lg-6 col-md-12 col-12 mb-3 offset-xl-1 mt-lg-n10">
              <div class="ps-lg-7">
                <span
                  class=" text-dark-50 ls-md text-uppercase
                fw-semi-bold"
                >
                  Technology
                </span>
                <h2 class="display-5 mt-4 mb-3 fw-bold text-purple">
                  Innovation on the GO!
                </h2>
                <div className="fs-3 mb-lg-20">
                  We believe that fast growth comes with rapid adaptation to the
                  latest technological advancements. To maintain maximum speed
                  and efficiency for our customers, we constantly improve on our
                  App almost regularly.{" "}
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-12 offset-lg-3 text-center mb-5 mb-lg-10 pt-12">
            <h1 class="display-4 fw-bold text-purple2">
              We build on your view
            </h1>
          </div>

          <div class="row align-items-center pt-10 pt-lg-10">
            <div class="col-xl-5 col-lg-5 col-12 mb-4 text-center">
              <img
                src="../assets/images/about/feedback.svg"
                class="mt-n10 mt-lg-n16 gallery__img"
                width="100%"
              />
            </div>

            <div class="col-xl-6 col-lg-6 col-md-12 col-12 mb-3 offset-xl-1 mt-lg-n10">
              <div class="ps-lg-7">
                <span
                  class=" text-dark-50 ls-md text-uppercase
                fw-semi-bold"
                >
                  Feedback
                </span>
                <h2 class="display-5 mt-4 mb-3 fw-bold text-purple">
                  Before We Speak, Act. We Listen
                </h2>
                <div className="fs-3 mb-lg-20">
                  The Customer is the highest-ranking position in Smart Edu.
                  They contribute to the development of our products by giving
                  us feedback and complaints. We get kudos a lot, too.{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white pt-5 pb-8">
        <div class="container-fluid">
          <div class="col-lg-6 col-12 offset-lg-3 text-center mb-6">
            <div>
              <img src="../../assets/images/background/star.svg" width="100%" />
            </div>
            <h1 class="display-5 mb-2 fw-bold text-purple2">
              Try us Now! All-In-One System
            </h1>
            <p className="fs-4 text-dark mt-3">
              We prepared the best school management plan for you to start. You
              will be able to adjust plans as you go - our custom-built
              algorithm will provide suggestions based on your usage.
            </p>
          </div>

          <div class="col-lg-10 col-12 offset-lg-1">
            <div className="card shadow">
              <div className="card-header bg-purple2 text-center">
                <h1 class="display-5 mb-2 text-purple2">Gold Plus Plan</h1>
              </div>
              <div className="card-body row align-items-center">
                <div className="col-auto px-lg-0 px-6">
                  <ul class="list-unstyled mb-0">
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Student management service</span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Teacher management service</span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Admin management service</span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>
                        <span class="fw-bold text-dark">Unlimited </span>storage
                      </span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>
                        <span class="fw-bold text-dark">
                          2 Parent Dispatch{" "}
                        </span>{" "}
                        system{" "}
                        <span class="badge rounded-pill bg-primary">
                          (SMS and Email Services)
                        </span>
                      </span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Data file system</span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Access to support forums</span>
                    </li>
                  </ul>
                </div>
                <div
                  class="col-auto ms-5 d-lg-flex d-none"
                  style={{ height: "200px" }}
                >
                  <div class="vr"></div>
                </div>

                <div className="col-auto text-center mb-5 mt-5 mb-lg-0 mt-lg-0 ms-lg-0 ms-8">
                  <div class="d-flex justify-content-center mb-4 text-purple">
                    <span class="h3 mb-0 fw-bold text-purple">₦</span>
                    <div class="toggle-price-content display-3">1500</div>
                    <span
                      class="align-self-end mb-1 ms-2 toggle-price-content fw-bolder"
                      data-price-monthly="/Monthly"
                      data-price-yearly="/Yearly"
                    >
                      / Student
                    </span>
                  </div>
                  <div class="ms-auto mt-3 mt-lg-0">
                    <a
                      class="btn btn-primary text-white"
                      style={{ borderRadius: "30px" }}
                      onClick={Signup}
                    >
                      Get Started Now
                    </a>
                  </div>
                </div>

                <div
                  class="col-auto me-10 d-lg-flex d-none"
                  style={{ height: "200px" }}
                >
                  <div class="vr"></div>
                </div>
                <div className="col-auto px-lg-0 px-6 d-none d-lg-block">
                  <ul class="list-unstyled mb-0">
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>
                        <span class="fw-bold text-dark">E-Report </span> system{" "}
                        <span class="badge rounded-pill bg-success">
                          (Full version)
                        </span>
                      </span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>
                        <span class="fw-bold text-dark">E-Learning </span>{" "}
                        system{" "}
                        <span class="badge rounded-pill bg-success">
                          (Full version)
                        </span>
                      </span>
                    </li>

                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span class="fw-bold text-dark">Free </span> Website
                      design
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Data file system </span>{" "}
                      <span class="badge rounded-pill bg-success">
                        (Advanced)
                      </span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>Bulk editing </span>
                    </li>
                    <li class="mb-1">
                      <span class="text-success me-1">
                        <i class="mdi mdi-check-circle-outline fs-4"></i>
                      </span>
                      <span>12 / 5 support</span>
                    </li>
                  </ul>
                </div>
                <div className="pt-lg-5 d-grid">
                  <hr className="my-2 text-purple2" />
                  <Link to="/packages" class="btn btn-link text-purple2 mb-2">
                    View full package details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default About;
