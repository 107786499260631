import React, { useState, useEffect } from "react";

import { Link, useParams, useNavigate } from "react-router-dom";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const Activate = () => {

  const navigate = useNavigate();

  useEffect(() => {
       window.scrollTo(0, 0);
       fetchData();
  }, []);

  const [users, setUser] = useState({});

  const fetchData = () => {
    axios({
      method: "post",
      url: "/v2/auths/",
      data: {
        token: id,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "failed") {
          toast.error("Error: Request Failed! Check your connection..");
        } else {
          setUser(view.user_data.data);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check your connection..");
      });
  };

  const { id } = useParams();

  const year = new Date().getFullYear();

  const [school, setSchool] = useState('');

  const school_name = users.school;

  const Signup = (e) => {
    e.preventDefault();

    const user = {
      school,
      school_name,
    };

    axios({
      method: "post",
      url: "/v2/activate/",
      data: user,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        const view = response.data;
        if (response.data.message === "success") {
          toast.success("Success: Hurray Account has been created!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-center",
            icon: true,
            autoClose: 6000,
          });

          navigate("../sync/" + id);

        }else if (response.data.message === "exist") {
          toast.error("Error: Try another abbrevation! Already exists!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

        }
        else {
          toast.error("Failed: Activation Failed! Try Again!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.", {
          hideProgressBar: true,
          draggable: true,
          position: "top-right",
          icon: true,
          autoClose: 6000,
        });
      });

  };

  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-default shadow-none fixed-top">
        <div className="container-fluid px-0 pt-2">
          <Link to="/" class="navbar-brand me-lg-8">
            <img src="../assets/smlogo.png" width="120" alt="" />
          </Link>

          <div class="text-dark fs-5 d-block d-xl-none">Showing 3 of 3</div>

          <div class="collapse navbar-collapse" id="navbar-default">
            <ul class="navbar-nav">
              <li class="nav-item px-4">
              <Link to="/cart" class="nav-link">1. Cart</Link>
              </li>
              <li class="nav-item px-4">
                <a class="nav-link">2. Build your trial</a>
              </li>
              <li class="nav-item px-4">
                <a class="nav-link fw-bold">3. App Activation</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div class="container-fluid">
        <div class="py-10 py-lg-10">
          <div class="container">
            <div class="row mb-8 justify-content-center">
              <div class="col-lg-6 col-md-12 col-12 text-center">
                <div>
                  <img
                    src="../../assets/images/background/star.svg"
                    width="100%"
                  />
                </div>
                <h1 class="mb-2 display-4 fw-bold text-purple2">App Activation</h1>
              </div>
            </div>

            <div className="col-lg-8 offset-lg-4">
              <ul class="list-group list-group-flush list-timeline-activity">
                <li class="list-group-item px-0 pt-0 border-0 pb-6">
                  <div class="row position-relative">
                    <div class="col-auto">
                      <div class="icon-shape icon-xl bg-light-success text-dark-success rounded-circle">
                        <i class="bi bi-check-lg fs-3"></i>
                      </div>
                    </div>
                    <div class="col ms-n3">
                      <h4 class="h4 mt-2">
                        Account has been created successfully
                      </h4>
                    </div>
                  </div>
                </li>

                <li class="list-group-item px-0 pt-0 border-0 pb-6">
                  <div class="row position-relative">
                    <div class="col-auto">
                      <div class="icon-shape icon-xl bg-light-primary text-dark-primary rounded-circle">
                        <i class="bi bi-hourglass-split fs-3"></i>
                      </div>
                    </div>
                    <div class="col ms-n3">
                      <h4 class="h4 mt-2 mt-lg-3">
                        App sync and module build in progress
                        <span
                          class="spinner-border spinner-border-sm text-primary mt-2 ms-3"
                          role="status"
                        >
                          <span class="sr-only"></span>
                        </span>
                      </h4>
                    </div>
                  </div>
                </li>

                <li class="list-group-item px-0 pt-0 border-0 pb-6">
                  <div class="row position-relative">
                    <div class="col-auto">
                      <div class="icon-shape icon-xl bg-light-success text-dark-success rounded-circle">
                        <i class="bi bi-columns-gap fs-3"></i>
                      </div>
                    </div>
                    <div class="col ms-n3">
                      <h4 class="h4 mt-2 mt-lg-3">
                        {users.plan === 'Gold Plus' || users.plan === 'Diamond Plus' ?
                        <>Result-Dispatch type is Double Parent Dispatch</>
                        :
                        <>Result-Dispatch type is Single Parent Dispatch</>
                        }
                      </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-lg-8 offset-lg-2 pt-2 pb-6">
              <form class="row px-lg-16 px-md-14" onSubmit={Signup}>
                <div class="mb-3 col-md-7 col-12 ps-md-0">
                  <input
                    maxLength={4}
                    type="text"
                    class="form-control border border-dark"
                    placeholder="E.g; SAC"
                    value={school}
                    id="name-input"
                    onChange={(e) => setSchool(e.target.value)}
                  />
                </div>

                <div class="d-grid mb-3 col-md-5 col-12 ps-md-0">
                  <button
                    class="btn btn-purple btn-lg text-white"
                    type="submit"
                    style={{ borderRadius: "30px" }}
                  >
                    Request Access
                  </button>
                </div>
                <div class="text-start text-dark col-12 fw-medium ps-lg-0">
                  Enter an abbreviation of school e.g;
                  <span class="text-primary"> St. Augustine (SAC)</span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default Activate;
