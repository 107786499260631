
import { createSlice } from '@reduxjs/toolkit';

const initialStateValue = {fullname: "", email: "", phone: "", address: "", job: "", 
birth: "", revenue: "0.00", schools: "2", bonus: "0.00", invest: "0.00", zenith: "",
polaris: "", access: "", total: "", invest_status: "", invest_total: "", state: "",
sex: "", plan_type: ""};

export const userSlice = createSlice({
    name: 'user',
    initialState: {value: initialStateValue},
    reducers: {
        register: (state, action) =>{
            state.value = action.payload;
        },

        logout: (state) =>{
            state.value = initialStateValue
        },
    },
});

export const {register, logout} = userSlice.actions;

export default userSlice.reducer;
