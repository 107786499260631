import React, { useState, useEffect } from "react";

import { useNavigate, Link } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import Footer from "../Footer";

const Contact = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [school, setSchool] = useState("None");

  const Signup = (e) => {
    e.preventDefault();
    navigate("../signup/" + school + "/Gold Plus");
  };

  const [progress, setProgress] = useState(20);

  return (
    <div onLoad={() => setProgress(100)}>
      <LoadingBar
        color="#B53471"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <nav class="navbar navbar-expand-lg navbar-default shadow-none fixed-top py-2">
        <div className="container-fluid px-0">
          <Link to="/" class="navbar-brand">
            <img src="../assets/smlogo.png" width="110" alt="" />
          </Link>

          <div class="ms-16 d-block d-xl-none d-lg-none">
            <Link to="/cart" class="text-purple2 fs-10 fw-medium">
              <i class="bi bi-cart"></i>
            </Link>
          </div>

          <button
            class="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-default"
            aria-controls="navbar-default"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="icon-bar top-bar mt-0"></span>
            <span class="icon-bar middle-bar"></span>
            <span class="icon-bar bottom-bar"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbar-default">
            <ul class="navbar-nav">
              <li class="nav-item px-3">
                <Link class="nav-link" to="/products">
                  Products
                </Link>
              </li>
              <li class="nav-item px-3">
                <Link class="nav-link" to="/packages">
                  Packages
                </Link>
              </li>
              <li class="nav-item dropdown px-3">
                <a
                  class="nav-link fw-bold dropdown-toggle"
                  href="#"
                  id="navbarLanding"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Company
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-md shadow-none"
                  aria-labelledby="navbarLanding"
                >
                  <li>
                    <h4 class="dropdown-header">Company</h4>
                  </li>
                  <li>
                    <Link to="/about-us" class="dropdown-item">
                      About us
                    </Link>
                  </li>

                  <li>
                    <Link to="/contact-us" class="dropdown-item">
                      Contact us
                    </Link>
                  </li>

                  <li>
                    <Link to="/forum" class="dropdown-item">
                      Community Forum
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>

            <div class="ms-auto mt-3 mt-lg-0">
              <a
                class="btn btn-purple text-white shadow-lg"
                style={{ borderRadius: "30px" }}
                onClick={Signup}
              >
                Try us out for Free
              </a>
            </div>

            <div class="ms-lg-4 mt-3 ms-0 mt-lg-0">
              <Link
                to="/cart"
                class="btn fs-4 fw-medium shadow-lg"
                style={{
                  borderRadius: "30px",
                  background: "transparent",
                  borderColor: "#2c4f40",
                  color: "#2c4f40",
                }}
              >
                <i class="bi bi-cart"></i> Cart
              </Link>
            </div>
          </div>
        </div>
      </nav>

      <div class="container-fluid bg-white pt-8 pb-lg-5 pb-1">
        <div class="row align-items-center min-vh-100">
          <div
            class="col-lg-6 d-lg-flex align-items-center w-lg-50 
          min-vh-lg-100 position-fixed-lg bg-cover top-0
            right-0 pt-8 py-lg-0"
            style={{
              backgroundImage: `url(../assets/images/about/contact.svg)`,
            }}
          >
            <div class="px-4 px-xl-20 py-20 py-lg-20"></div>
          </div>

          <div class="col-lg-6 col-md-12 col-12">
            <div class="px-xl-15 px-md-8 py-8 py-lg-0">
              <div class="text-purple2 ms-2">
                <h1 class="display-4 fw-bold text-purple2">Get In Touch.</h1>
                <p class="fs-3 text-dark">
                  We are closer to you than ever. Contact us through the
                  following channels available , For enquires please call the
                  number below or contact us via E-mail.
                </p>
                <div class="mt-8">
                  <p class="fs-4 mb-4">
                    If you are seeking support please visit our{" "}
                    <a href="/#/forum" target="_blank">
                      Forum Room
                    </a>{" "}
                    before reaching out directly.
                  </p>

                  <p class="fs-4">
                    <i
                      class="bi bi-telephone text-primary
                      me-2"
                    ></i>{" "}
                    + (234) 706 3354 504
                  </p>
                  <p class="fs-4">
                    <i
                      class="bi bi-envelope-open
                      text-primary me-2"
                    ></i>{" "}
                    customerservice@smartedung.com
                  </p>
                  <p class="fs-4 d-flex">
                    <i
                      class="bi bi-geo-alt
                      text-primary me-2"
                    ></i>{" "}
                    27, Tunde Alabi Crescent, Victory Estate, Ejigbo, Lagos
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
};

export default Contact;
