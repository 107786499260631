import React, { useState, useEffect } from "react";

import { Link, useParams, useNavigate } from "react-router-dom";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const Signup = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();

  const { plan } = useParams();

  const year = new Date().getFullYear();

  const [school, setSchool] = useState(id);
  const [pop, setPop] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);

  const [school_err, setschool_err] = useState("");

  const key_checker = localStorage.getItem("key");

  if (!key_checker) {
    const key = Math.random().toString(36).substr(2);

    localStorage.setItem("key", key);
  }

  const Signup = (e) => {
    e.preventDefault();

    const user = {
      school,
      pop,
      name,
      email,
      phone,
      plan,
      key_checker
    };

    axios({
      method: "post",
      url: "/v2/",
      data: user,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        const view = response.data;
        if (response.data.message === "success") {
          toast.success("Success: Account has been created!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-center",
            icon: true,
            autoClose: 6000,
          });

          navigate("../build/" + view.token);

        } else if (response.data.message === "exist") {
          toast.error("Failed: School name already exists!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          window.scrollTo(0, 0);
          
          setschool_err("School name already exists");

        } else {
          toast.error("Failed: Check your fields! Incorrect Details", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.", {
          hideProgressBar: true,
          draggable: true,
          position: "top-right",
          icon: true,
          autoClose: 6000,
        });
      });
  };

  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-default shadow-none fixed-top">
        <div className="container-fluid px-0 pt-2">
          <Link to="/" class="navbar-brand me-lg-8">
            <img src="../assets/smlogo.png" width="120" alt="" />
          </Link>

          <div class="text-dark fs-5 d-block d-xl-none">Showing 1 of 3</div>

          <div class="collapse navbar-collapse" id="navbar-default">
            <ul class="navbar-nav">
              <li class="nav-item px-4">
                <a class="nav-link fw-bold" href="#">
                  1. Create Account
                </a>
              </li>
              <li class="nav-item px-4">
                <a class="nav-link" href="#">
                  2. Build your trial
                </a>
              </li>
              <li class="nav-item px-4">
                <a class="nav-link" href="#">
                  3. App Activation
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div class="container-fluid pt-xl-8 pt-5">
        <div class="row align-items-center min-vh-lg-100">
          <div
            class="d-none d-lg-flex align-items-center w-lg-50
          min-vh-lg-100 position-fixed-lg bg-cover bg-white"
            style={{
              backgroundImage: `url(../assets/images/background/back1.svg)`,
            }}
          >
            <div class="px-4 px-xl-20 py-20"></div>
          </div>

          <div
            class="d-lg-flex align-items-center w-lg-50
          min-vh-lg-100 position-fixed-lg bg-cover bg-white"
            style={{
              backgroundImage: `url(../assets/images/background/back2.svg)`,
            }}
          >
            <div class="px-xl-6 px-md-8 px-2 pt-8 pt-lg-8">
              <div class="text-dark ms-xl-8">
                <h1 class="display-4 text-center fw-bold text-purple">
                  Create account
                </h1>
                <form className="row mt-4" onSubmit={Signup}>
                  <div class="col-lg-6 mb-2">
                    <label class="form-label fw-bold text-dark" for="textInput">
                      School Name
                    </label>
                    <input
                      type="text"
                      id="textInput"
                      class="form-control form-control-md border border-1 border-dark"
                      placeholder="E.g; Grace Ville"
                      defaultValue={id}
                      required
                      onChange={(e) => {
                        setSchool(e.target.value);
                        setschool_err("");
                      }}
                    />
                    <p className="text-danger mt-2">{school_err}</p>
                  </div>

                  <div class="col-lg-6 mb-2">
                    <label class="form-label fw-bold text-dark" for="textInput">
                      School Population
                    </label>
                    <input
                      type="number"
                      id="textInput"
                      class="form-control form-control-md border border-1 border-dark"
                      placeholder="E.g; 250"
                      required
                      onChange={(e) => setPop(e.target.value)}
                    />
                  </div>

                  <div class="col-lg-12 mb-2">
                    <p className="fw-bold text-dark mt-3">
                      {" "}
                      Fill in school Admin details that can be contacted
                    </p>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <label class="form-label fw-bold text-dark" for="textInput">
                      Admin Name
                    </label>
                    <input
                      type="text"
                      id="textInput"
                      class="form-control form-control-md border border-1 border-dark"
                      placeholder="Your Name"
                      required
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>

                  <div className="col-lg-6 mb-5">
                    <div class="mb-3">
                      <label
                        class="form-label fw-bold text-dark"
                        for="selectOne"
                      >
                        App Plan
                      </label>
                      <select
                        class="form-select form-select-md border border-1 border-dark text-dark"
                        aria-label="Default select example"
                        required
                      >
                        <option selected value={plan}>
                          {plan} Plan
                        </option>
                        <option value="Silver">Silver Plan</option>
                        <option value="Gold">Gold Plan</option>
                        <option value="Gold Plus">Gold Plus Plan</option>
                        <option value="Diamond">Diamond Plan</option>
                        <option value="Diamond Plus">Diamond Plus Plan</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <label class="form-label fw-bold text-dark" for="textInput">
                      Admin Email
                    </label>
                    <input
                      type="email"
                      id="textInput"
                      class="form-control form-control-md border border-1 border-dark"
                      placeholder="Email Address"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div class="col-lg-6 mb-5">
                    <label class="form-label fw-bold text-dark" for="textInput">
                      Admin Phone Number
                    </label>
                    <input
                      type="text"
                      id="textInput"
                      class="form-control form-control-md border border-1 border-dark"
                      placeholder="Phone Number"
                      required
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>

                  <div class="col-lg-12 text-center mb-5">
                    <button
                      type="submit"
                      class="btn btn-purple btn-lg text-white mb-2"
                      style={{ borderRadius: "30px" }}
                    >
                      Continue to "Build your trial"{" "}
                      <i class="bi bi-arrow-right"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default Signup;
